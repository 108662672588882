import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { updateQuantityStock } from "../../crud/entrancesStockCrud";
import { setEntrance, setSearchEntrance } from "../../../redux/ducks/entrance.duck";
import CardColorContainer from "../../components/CardColorContainer";
import PDFUploaderTab from "../../components/PDFUploaderTab";
import CardColorItem from "../../components/CardColorItem";
import SimpleInput from "../../components/SimpleInput";
import PageLayout from "../../components/PageLayout";
import UtilInputs from "../../components/UtilInputs";
import TabsButton from "../../components/TabsButton";
import notice from "../../components/Notice";
import RecurrentMaterialAccordion from "./Tabs/RecurrentMaterialAccordion";
import CleanMaterialAccordion from "./Tabs/CleanMaterialAccordion";
import StockMaterialAccordion from "./Tabs/StockMaterialAccordion";
import {
  accordionBatchColumns,
  amountInputs,
  cardItems,
  cleanBatchColumns,
  entranceFilterInputs,
  formInputs,
  getContractsSupplier,
  getRequirementsContract,
  formInputsWithUserPermission,
  defaultEntrance,
} from "../../utils/entranceData";
import { isAValidInteger } from "../../utils/inputValidations";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { setFiles } from "../../../redux/ducks/files.duck";
import { concat } from "lodash";
import { stockInputs } from "./utils/stock-inputs.utils";
import { FROrdGaInputs } from "./utils/or-fr-ga.utils";
import { LimpiezaDevoInputs } from "./utils/limpieza_devo.utils";

const EntranceForm = () => {
  const {
    isEntranceListStockPage,
    isEntranceListRecurrentesPage,
    isEntranceListOrdinariasPage,
    isEntranceListRevolvente,
    isEntranceListGastos,
  } = useSelector((store) => store.pathname);
  const entradas = useSelector((store) => store.pathname);
  const { entrance, searchEnatrance, selectedIds } = useSelector(
    (state) => state.entrance
  );
  const { permisos } = useSelector((state) => state.auth);
  const { isSearchPage } = useSelector((store) => store.pathname);
  const dispatch = useDispatch();
  const history = useHistory();
  const [contracts, setContracts] = useState([]);
  const [requirements, setRequirements] = useState([]);

  const REQUIRED_ITEMS = entrance.id
    ? entrance.total_requirement_items_amount
    : entrance.requisition_item_details.reduce(
        (acc, x) => acc + Number(x.requirement_item_total_amount),
        0
      );
  const DELIVERED_ITEMS = entrance.requisition_item_details.reduce(
    (acc, x) => acc + Number(x.item_total_delivered_amount),
    0
  );
  const CURRENT_DELIVERED_ITEMS = entrance.requisition_item_details.reduce(
    (acc, x) => acc + Number(x.cf_cantidad),
    0
  );
  const TO_DELIVER_ITEMS = Number(REQUIRED_ITEMS) - Number(DELIVERED_ITEMS);
  const subtotal =
    entrance.requisition_item_details.reduce(
      (acc, item) => acc + Number(item.subtotal),
      0
    ) || 0;
  const iva = entrance.requisition_item_details.reduce(
    (acc, item) => acc + Number((item.iva ? 0.16 * Number(item.subtotal) : 0).toFixed(4)),
    0
  );
  const total = subtotal + iva;

  const isNewStockEntrance =
    history.location.pathname === "/inventario-bienes-almacen-general/entradas/nuevo";

  useEffect(() => {
    // GET CONTRACTS LIST FROM SUPPLIER
    if (
      (entrance.tipo_entrada === "STOCK" || entrance.tipo_entrada === "RECURRENTE") &&
      entrance.supplier &&
      entrance.supplier?.text2
    )
      getContractsSupplier(entrance.supplier?.text2)
        .then((res) =>
          setContracts(
            res
              .filter((it) => it.contract_type === entrance.tipo_entrada)
              .map((req) => ({
                value: req.id,
                text: req.contract_number,
              }))
          )
        )
        .catch(console.log);
  }, [entrance.supplier, entrance.tipo_entrada]);

  useEffect(() => {
    // GET REQUIREMENTS LIST FROM CONTRACT
    getRequirementsContract(entrance.contract_number, setRequirements);
  }, [entrance.contract_number]);

  const handleForm = (e) => {
    const { name, value } = e.target;
    if (name === "cf_fol_bita" && !isAValidInteger(value))
      notice("El campo Folio de bitacora debe ser un número");
    else
      dispatch(
        isSearchPage
          ? setSearchEntrance({
              ...searchEnatrance,
              [name]: value,
            })
          : setEntrance({
              ...entrance,
              [name]: value,
            })
      );
  };

  const handleChange = ({ target }, i) => {
    // HANDLE FOR CHANGE WAREHOUSE ITEMS
    const { value } = target;
    let updatedState = entrance.requisition_item_details;
    updatedState[i] = value;
    dispatch(
      setEntrance({
        ...entrance,
        requisition_item_details: updatedState,
      })
    );
  };

  const handleClean = ({ target }, i) => {
    // HANDLE FOR CHANGE WARHOUSE ITEMS
    const { name, value } = target;

    let updatedState = entrance;
    updatedState[name] = value;

    dispatch(setEntrance({ ...updatedState }));
  };

  const handleSaveQuantity = (id, quantity) => {
    updateQuantityStock(id, quantity)
      .then(() => notice("Cantidad actualizada", "success"))
      .catch((e) => notice("Error, la cantidad de entrada supera la cantidad total"));
  };

  const handleClickTab = (id) => {
    history.push(`/inventario-bienes-almacen-general/entradas/resultados/${id}`);
  };

  const onSuccessUploadPDFs = (responses) => {
    const newAttachments = responses.map((res) => res.data);

    handleForm({
      target: {
        name: "attachment",
        value: [...entrance.all_attachments, ...newAttachments],
      },
    });
  };

  const onSucessRemovePDF = (deletedPDFId) => {
    const newAttachments = entrance.all_attachments.filter(
      (attachment) => attachment.id !== deletedPDFId
    );

    handleForm({
      target: {
        name: "attachment",
        value: [...entrance.all_attachments, ...newAttachments],
      },
    });
  };

  const onSuccessUploadWithotId = (files) => {
    dispatch(setFiles(files));
  };
  const entrada_precarga = permisos.permisos.includes("a-entrada-precarga");
  const entrada_borrador = permisos.permisos.includes("a-entrada-borrador");
  const entrada_cerrar = permisos.permisos.includes("a-entrada-cerrar");
  //?  STOCK
  let isStockEntranceAndUserPrecarga =
    entrance.tipo_entrada === "STOCK" && permisos.permisos.includes("a-entrada-precarga");

  let isStockEntranceAndUserBorrador =
    entrance.tipo_entrada === "STOCK" && permisos.permisos.includes("a-entrada-borrador");

  let isStockEntranceAndUserCerrar =
    entrance.tipo_entrada === "STOCK" && permisos.permisos.includes("a-entrada-cerrar");

  //?LIMPIEZA
  let isLimpiezaEntranceAndUserPrecarga =
    entrance.tipo_entrada === "LIMPIEZA" &&
    permisos.permisos.includes("a-entrada-precarga");
  let isLimpiezaEntranceAndUserBorrador =
    entrance.tipo_entrada === "LIMPIEZA" &&
    permisos.permisos.includes("a-entrada-borrador");

  let isLimpiezaEntranceAndUserCerrar =
    entrance.tipo_entrada === "LIMPIEZA" &&
    permisos.permisos.includes("a-entrada-cerrar");

  //? RECURRENTES

  let isRecurrenteEntranceAndUserPrecarga =
    entrance.tipo_entrada === "RECURRENTE" &&
    permisos.permisos.includes("a-entrada-precarga");
  let isRecurrenteEntranceAndUserBorrador =
    entrance.tipo_entrada === "RECURRENTE" &&
    permisos.permisos.includes("a-entrada-borrador");

  let isRecurrenteEntranceAndUserCerrar =
    entrance.tipo_entrada === "RECURRENTE" &&
    permisos.permisos.includes("a-entrada-cerrar");

  //? ORDINARIA
  let isOrdinariaEntranceAndUserPrecarga =
    entrance.tipo_entrada === "ORDINARIA" &&
    permisos.permisos.includes("a-entrada-precarga");
  let isOrdinariaEntranceAndUserBorrador =
    entrance.tipo_entrada === "ORDINARIA" &&
    permisos.permisos.includes("a-entrada-borrador");

  let isOrdinariaEntranceAndUserCerrar =
    entrance.tipo_entrada === "ORDINARIA" &&
    permisos.permisos.includes("a-entrada-cerrar");

  //? GASTOS Y FONDO
  let isGastosEntranceAndPrecarga =
    entrance.tipo_entrada === "GASTOSCOMPROBAR" &&
    permisos.permisos.includes("a-entrada-precarga");

  let isGastosEntranceAndCerrar =
    entrance.tipo_entrada === "GASTOSCOMPROBAR" &&
    permisos.permisos.includes("a-entrada-cerrar");
  let isFondoEntranceAndPrecarga =
    entrance.tipo_entrada === "FONDOREVOLVENTE" &&
    permisos.permisos.includes("a-entrada-precarga");
  let isFondoEntranceAndCerrar =
    entrance.tipo_entrada === "FONDOREVOLVENTE" &&
    permisos.permisos.includes("a-entrada-cerrar");

  let statusEntrada;
  if (
    entrance.tipo_entrada === "LIMPIEZA" ||
    entrance.tipo_entrada === "STOCK" ||
    entrance.tipo_entrada === "RECURRENTE"
  ) {
    if (!entrance.entrada_cerrada) {
      statusEntrada = "En proceso";
    } else if (
      entrance.entrada_cerrada &&
      entrance.registra_remision &&
      !entrance.registra_factura
    ) {
      statusEntrada = "Afectada/En proceso";
    } else if (
      entrance.entrada_cerrada &&
      !entrance.registra_remision &&
      !entrance.registra_factura
    ) {
      statusEntrada = "Afectada";
    } else if (entrance.entrada_cerrada && entrance.registra_factura) {
      statusEntrada = "Afectada/Cerrada";
    } else {
      statusEntrada = "Abierto";
    }
  } else if (entrance.tipo_entrada === "ORDINARIA") {
    if (entrance.registra_remision && !entrance.registra_factura) {
      statusEntrada = "En proceso";
    } else if (entrance.registra_factura && !entrance.registra_remision) {
      statusEntrada = "Cerrada";
    } else {
      statusEntrada = "Abierto";
    }
  } else if (
    entrance.tipo_entrada === "FONDOREVOLVENTE" ||
    entrance.tipo_entrada === "GASTOSCOMPROBAR"
  ) {
    statusEntrada = entrance.entrada_cerrada ? "Cerrada" : "Abierto";
  } else if (entrance.tipo_entrada === "DEVOLUCION") {
    statusEntrada = entrance.entrada_cerrada ? "Afectada/Cerrada" : "Abierto";
  }
  // if (entrance.entrada_cerrada && !entrance.registra_factura) {
  //   statusEntrada = "Afectada";
  // } else if (entrance.entrada_cerrada && entrance.registra_factura) {
  //   statusEntrada = "Cerrada";
  // } else if (!entrance.entrada_cerrada) {
  //   statusEntrada = "En proceso";
  // }
  const requiresPermisions = Boolean(permisos.permisos.length);
  useEffect(() => {
    if (entrance.tipo_entrada !== "") {
      dispatch(setEntrance({ ...defaultEntrance, tipo_entrada: entrance.tipo_entrada }));
    }
  }, [entrance.tipo_entrada]);
  return (
    <PageLayout
      title={
        isSearchPage
          ? "Busqueda de entradas"
          : entrance.id
          ? "Información de entrada"
          : "Nueva entrada"
      }
      header={
        entrance.id &&
        selectedIds.length > 1 && (
          <TabsButton
            list={selectedIds}
            selected={entrance.id}
            handleClickTab={handleClickTab}
          />
        )
      }
    >
      {!isSearchPage && entrance.tipo_entrada === "" && (
        <UtilInputs
          formValues={isSearchPage ? searchEnatrance : entrance}
          onValuesChange={handleForm}
          inputValues={[
            {
              label: "Tipo de entrada",
              name: "tipo_entrada",
              type: "dropdown-chip",
              required: true,
              options: [
                { value: "", text: "Seleccione un tipo" },
                { value: "STOCK", text: "Stock", color: "primary" },
                { value: "ORDINARIA", text: "Ordinaria", color: "info" },
                { value: "RECURRENTE", text: "Recurrente", color: "danger" },
                {
                  value: "LIMPIEZA",
                  text: "Mantenimiento y Limpieza",
                  color: "warning",
                },
                {
                  value: "FONDOREVOLVENTE",
                  text: "Fondo revolvente",
                  color: "primary",
                },
                {
                  value: "GASTOSCOMPROBAR",
                  text: "Gastos a comprobar",
                  color: "success",
                },
              ],
            },
          ]}
          width={200}
        />
      )}
      {entrance.tipo_entrada !== "" && (
        <UtilInputs
          formValues={isSearchPage ? searchEnatrance : entrance}
          onValuesChange={handleForm}
          inputValues={
            isSearchPage
              ? entranceFilterInputs(true)
              : entrance.tipo_entrada === "STOCK" ||
                entrance.tipo_entrada === "RECURRENTE"
              ? stockInputs(
                  contracts,
                  requirements,
                  statusEntrada,
                  entrada_precarga,
                  entrada_borrador,
                  entrada_cerrar,
                  entrance.entrada_cerrada,
                  isNewStockEntrance,
                  entrance.tipo_entrada
                )
              : entrance.tipo_entrada === "ORDINARIA" ||
                entrance.tipo_entrada === "FONDOREVOLVENTE" ||
                entrance.tipo_entrada === "GASTOSCOMPROBAR"
              ? FROrdGaInputs(
                  requirements,
                  statusEntrada,
                  entrada_precarga,
                  entrada_borrador,
                  entrada_cerrar,
                  entrance.entrada_cerrada,
                  isNewStockEntrance,
                  entrance.tipo_entrada
                )
              : LimpiezaDevoInputs(
                  requirements,
                  statusEntrada,
                  entrada_precarga,
                  entrada_borrador,
                  entrada_cerrar,
                  entrance.entrada_cerrada,
                  isNewStockEntrance,
                  entrance.tipo_entrada
                )
          }
          width={200}
        />
      )}

      {requiresPermisions ? (
        /*   <UtilInputs
          formValues={isSearchPage ? searchEnatrance : entrance}
          onValuesChange={handleForm}
          inputValues={
            isSearchPage
              ? entranceFilterInputs(true)
              : formInputsWithUserPermission(
                  statusEntrada,
                  contracts,
                  requirements,
                  Boolean(entrance.id),
                  entrance.tipo_entrada,
                  entrance.entrada_cerrada,
                  isEntranceListStockPage,
                  isEntranceListRecurrentesPage,
                  isEntranceListOrdinariasPage,
                  isEntranceListRevolvente,
                  isEntranceListGastos,
                  isNewStockEntrance,
                  entrance.entrada_pre_registro,
                  (isStockEntranceAndUserPrecarga = isStockEntranceAndUserPrecarga),
                  (isStockEntranceAndUserBorrador = isStockEntranceAndUserBorrador),
                  (isStockEntranceAndUserCerrar = isStockEntranceAndUserCerrar),
                  (isLimpiezaEntranceAndUserPrecarga = isLimpiezaEntranceAndUserPrecarga),
                  (isLimpiezaEntranceAndUserBorrador = isLimpiezaEntranceAndUserBorrador),
                  (isLimpiezaEntranceAndUserCerrar = isLimpiezaEntranceAndUserCerrar),
                  (isRecurrenteEntranceAndUserPrecarga = isRecurrenteEntranceAndUserPrecarga),
                  (isRecurrenteEntranceAndUserBorrador = isRecurrenteEntranceAndUserBorrador),
                  (isRecurrenteEntranceAndUserCerrar = isRecurrenteEntranceAndUserCerrar),
                  (isOrdinariaEntranceAndUserPrecarga = isOrdinariaEntranceAndUserPrecarga),
                  (isOrdinariaEntranceAndUserBorrador = isOrdinariaEntranceAndUserBorrador),
                  (isOrdinariaEntranceAndUserCerrar = isOrdinariaEntranceAndUserCerrar),
                  (isGastosEntranceAndPrecarga = isGastosEntranceAndPrecarga),
                  (isGastosEntranceAndCerrar = isGastosEntranceAndCerrar),
                  (isFondoEntranceAndPrecarga = isFondoEntranceAndPrecarga),
                  (isFondoEntranceAndCerrar = isFondoEntranceAndCerrar)
                )
          }
          width={200}
        /> */
        <></>
      ) : (
        <UtilInputs
          formValues={isSearchPage ? searchEnatrance : entrance}
          onValuesChange={handleForm}
          inputValues={
            isSearchPage
              ? entranceFilterInputs(true)
              : formInputs(
                  statusEntrada,
                  contracts,
                  requirements,
                  Boolean(entrance.id),
                  entrance.tipo_entrada,
                  entrance.entrada_cerrada,
                  isEntranceListStockPage,
                  isEntranceListRecurrentesPage,
                  isEntranceListOrdinariasPage,
                  isEntranceListRevolvente,
                  isEntranceListGastos,
                  isNewStockEntrance,
                  entrance.entrada_pre_registro
                )
          }
          width={200}
        />
      )}

      {!isSearchPage && (
        <SimpleInput
          name="cf_observaciones"
          label="Observaciones"
          width="100%"
          value={entrance.cf_observaciones}
          onChange={handleForm}
          disabled={isStockEntranceAndUserBorrador}
        />
      )}
      {!isSearchPage && (
        <SimpleInput
          multiline
          rows={5}
          width="100%"
          label="Descripción"
          name="ordinaria_descripcion"
          onChange={handleForm}
          value={entrance.ordinaria_descripcion}
          disabled={entrance.entrada_cerrada || isStockEntranceAndUserBorrador}
        />
      )}
      {!isSearchPage &&
        !isStockEntranceAndUserPrecarga &&
        !isRecurrenteEntranceAndUserPrecarga && (
          <>
            {(entrance.tipo_entrada === "STOCK" ||
              entrance.tipo_entrada === "RECURRENTE") && (
              <>
                <h6 className="my-5 text-muted">Información de bienes requeridos</h6>
                <div className="d-flex justify-content-between align-items-end">
                  <CardColorContainer
                    cards={cardItems(REQUIRED_ITEMS, DELIVERED_ITEMS, TO_DELIVER_ITEMS)}
                  />
                  <div>
                    <CardColorItem
                      title="Entrada"
                      color="warning"
                      quantity={CURRENT_DELIVERED_ITEMS}
                      src={toAbsoluteUrl("/media/svg/icons/Home/Commode2.svg")}
                    />
                  </div>
                </div>
              </>
            )}
            <div className="mb-5 mt-3">
              {entrance.tipo_entrada === "STOCK" &&
                !entrance.limpieza &&
                entrance.requisition_item_details?.map((item, i) => (
                  <StockMaterialAccordion
                    key={item.id}
                    i={i}
                    item={item}
                    disabled={entrance.entrada_cerrada}
                    columns={accordionBatchColumns(entrance.id)}
                    handleChange={handleChange}
                    handleSaveQuantity={handleSaveQuantity}
                  />
                ))}
              {entrance.tipo_entrada === "RECURRENTE" &&
                entrance.requisition_item_details?.map((item, i) => (
                  <RecurrentMaterialAccordion
                    key={item.id}
                    i={i}
                    item={item}
                    handleChange={handleChange}
                    entrance={entrance}
                  />
                ))}

              {entrance.tipo_entrada === "LIMPIEZA" &&
                !isLimpiezaEntranceAndUserPrecarga && (
                  <CleanMaterialAccordion
                    items={entrance.requisition_item_details}
                    disabled={entrance.entrada_cerrada}
                    columns={cleanBatchColumns(entrance.entrada_cerrada)}
                    handleChange={handleClean}
                  />
                )}
              {(entrance.tipo_entrada === "STOCK" ||
                entrance.tipo_entrada === "RECURRENTE" ||
                entrance.tipo_entrada === "LIMPIEZA") && (
                <div className="mt-4">
                  <UtilInputs
                    width={200}
                    formValues={{
                      subtotal,
                      iva,
                      total,
                    }}
                    onValuesChange={handleForm}
                    inputValues={amountInputs(entrance.tipo_entrada === "LIMPIEZA" && 4)}
                  />
                </div>
              )}
            </div>
            {isSearchPage && (
              <PDFUploaderTab
                pdfTitleName="Entradas"
                enableSelectType={true}
                attachment_pdfs={entrance.attachment}
                attach_model="asset_acquisition"
                field="pdfs"
                id={entrance.id}
                onSuccessUpload={
                  entrance.id ? onSuccessUploadPDFs : onSuccessUploadWithotId
                }
                onSucessRemove={onSucessRemovePDF}
              />
            )}
          </>
        )}
    </PageLayout>
  );
};

export default EntranceForm;
