import React, { useState } from "react";
import {
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import NumberFormat from "react-number-format";
import InputMask from "react-input-mask";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    userSelect: "none",
    width: ({ isMobile, width }) => (width ? width : isMobile ? 200 : 130),
    maxWidth: ({ isMobile }) => isMobile && 200,
  },
  label: {
    marginLeft: 5,
    fontWeight: 500,
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: ({ disabled, touched, hover }) =>
      (touched || hover) && !disabled ? theme.palette.secondary.main : "black",
  },
}));

export const PriceNumberFormat = ({ inputRef, onChange, decimals, ...other }) => {
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: other.name,
            value: values.value,
          },
        });
      }}
      decimalScale={decimals}
      thousandSeparator
      fixedDecimalScale
      allowNegative={false}
    />
  );
};

export const NormalInput = (props) => <input {...props} />;

export const TextareaInput = (props) => (
  <textarea style={{ resize: "none", height: "auto" }} {...props} />
);

export const MaskInput = ({
  inputRef,
  value,
  onChange,
  name,
  mask,
  maskFormat,
  ...other
}) => {
  return (
    <InputMask
      {...other}
      formatChars={maskFormat}
      inputRef={inputRef}
      mask={mask}
      name={name}
      id={name}
      value={value}
      onChange={onChange}
    />
  );
};

const SimpleInput = ({
  location,
  focus,
  inputRef,
  label,
  placeholder,
  helperText,
  name,
  onChange = () => {},
  value,
  disabled = false,
  width = false,
  InputProps = {},
  labelStyle,
  onClick,
  autoComplete,
  onKeyDown = () => {},
  multiline,
  rows,
  required,
  errorMessage,
  onBlur,
  inputProps = {},
  maxLength,
  autoSelect,
  isPrice = false,
  isPassword = false,
  isPercentage = false,
  style = {},
  autofocus,
  decimals = 2,
  adornment,
  mask,
  maskFormat,
  showLowerCase,
  isPdf = false,
  autocompleteResults = null,
  showAutocomplete = false,
  onGetResult = null,
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [touched, setTouched] = useState(false);
  const [hover, setHover] = useState(false);
  const classes = useStyles({ isMobile, disabled, width, touched, hover });

  const onMouseLeave = (e) => {
    if (e?.target?.value && !isPassword && location === "secondments") {
      e.target.value = e.target.value
        .replace(/á/g, "a")
        .replace(/é/g, "e")
        .replace(/í/g, "i")
        .replace(/ó/g, "o")
        .replace(/ú/g, "u")
        .toUpperCase()
        .normalize("NFD");
    } else if (e?.target?.value && !isPassword) {
      e.target.value = e.target.value
        .toUpperCase()
        .normalize("NFD")
        .replace(/\p{Diacritic}/gu, "");
    }

    setTouched(false);
    onChange(e);
  };

  return (
    <div className={classes.container} style={{ position: "relative" }}>
      <Typography className={classes.label} style={labelStyle}>
        {label}
      </Typography>
      <TextField
        fullWidth
        autoFocus={focus === true ? true : false}
        inputRef={inputRef}
        size="small"
        variant="outlined"
        color="secondary"
        error={required && Boolean(errorMessage)}
        required={required}
        helperText={errorMessage || helperText}
        placeholder={placeholder ? placeholder.toUpperCase() : label?.toUpperCase()}
        name={name}
        onChange={onChange}
        value={value !== null ? value : ""}
        disabled={disabled}
        InputProps={{
          ...InputProps,
          style: style,
          startAdornment: (isPrice || adornment === "price") && (
            <InputAdornment position="start">$</InputAdornment>
          ),
          endAdornment: isPercentage ? (
            <InputAdornment position="end">%</InputAdornment>
          ) : isPdf ? (
            <InputAdornment position="end">.pdf</InputAdornment>
          ) : null,
          inputComponent: isPrice
            ? PriceNumberFormat
            : multiline
            ? TextareaInput
            : mask
            ? MaskInput
            : NormalInput,
          inputProps: {
            decimals,
            className: (!showLowerCase || !isPassword) && "text-uppercase",
            mask,
            maskFormat,
          },
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={onClick}
        autoComplete={autoComplete}
        onKeyDown={(e) => e.key === "Enter" && onKeyDown()}
        multiline={multiline}
        rows={rows}
        inputProps={inputProps}
        maxLength={maxLength}
        onBlur={onMouseLeave}
        onFocus={(e) => {
          setTouched(true);
          autoSelect && e.target.select();
        }}
      />
      {showAutocomplete && autocompleteResults && autocompleteResults.length !== 0 && (
        <ul
          style={{
            position: "absolute",
            marginTop: "5px",
            backgroundColor: "white",
            width: "100%",
            height: "120px",
            overflow: "scroll",
            top: "100%",
            zIndex: "100",
            listStyle: "none",
            padding: "0px",
            marginTop: "5px",
          }}
        >
          {autocompleteResults.map((result) => (
            <li
              key={result.place_id}
              onClick={() => onGetResult && onGetResult(result)}
              style={{
                cursor: "pointer",
                transition: "background-color 0.3s",
                width: "100%",
                padding: "7px 0px 7px 10px",
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "lightgray";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "transparent";
              }}
            >
              <b>{result.cve_articulo}</b> {result.text}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SimpleInput;
