import * as columnFormatters from "../components/column-formatters";
import notice from "../components/Notice";
import {
  getArticles,
  getArticlesClean,
  // getProviders,
  getProvidersAlmacen,
} from "../crud/catalogsCrud";
import { getContractList } from "../crud/contractsCrud";
import { listRequirements } from "../crud/requirementCrud";
import { inputFormatDate, requestFormatDate } from "./dates";
import { addEntryFilePDFAlmacen } from "../crud/entrancesStockCrud";
import { formatMoney } from "./expressions";
// import { useSelector } from 'react-redux';

// const { entrance, searchEnatrance, selectedIds } = useSelector((state) => state.entrance);
// const { permisos } = useSelector((state) => state.auth);
// let isStockEntranceAndUserPrecarga =
//   entrance.tipo_entrada === "STOCK" && permisos.permisos.includes("a-entrada-precarga");

export const columns = ({ onEye, onLock }) => [
  {
    dataField: "cf_no_registro",
    text: "No. Registro",
    sort: true,
  },
  {
    dataField: "cf_no_entrada",
    text: "No. Entrada",
    sort: true,
  },
  {
    dataField: "proveedor_razon_social",
    text: "Proveedor",
    sort: true,
  },
  {
    dataField: "created_at",
    text: "Fecha de registro",
    formatter: (value) => inputFormatDate(value),
    sort: true,
  },
  {
    dataField: "cf_fecha_entrada",
    text: "Fecha sello almacen",
    formatter: (value) => inputFormatDate(value),
    sort: true,
  },

  {
    dataField: "contract_number",
    text: "Pedido o Contrato",
    sort: true,
  },
  {
    dataField: "ordinaria_descripcion",
    text: "Descripción",
    sort: true,
    style: { minWidth: 110 },
  },
  {
    dataField: "cf_ord_area_solicitante",
    text: "Área solicitante",
    sort: true,
  },
  {
    dataField: "cf_no_remision",
    text: "No. de remisión",
    sort: true,
  },
  {
    dataField: "cf_fecha_remision",
    text: "Fecha sello remisión",
    formatter: (value) => inputFormatDate(value),
    sort: true,
  },
  {
    dataField: "cf_importe_remision",
    text: "Importe remisión",
    isPrice: true,
    formatter: (value) => (value !== null ? formatMoney(value) : ""),
    style: {
      whiteSpace: "nowrap",
      minWidth: 110,
    },
  },
  {
    dataField: "cf_no_factura",
    text: "No. de factura",
    sort: true,
    formatter: (cell) => cell?.trim(),
  },
  {
    dataField: "cf_fecha_factura",
    text: "Fecha sello factura",
    formatter: (value) => inputFormatDate(value),
    sort: true,
  },
  {
    dataField: "cf_importe_remision_factura",
    text: "Importe factura",
    isPrice: true,
    formatter: (value) => (value !== null ? formatMoney(value) : ""),
    style: {
      whiteSpace: "nowrap",
      minWidth: 110,
    },
  },
  {
    dataField: "tipo_entrada",
    text: "Tipo de entrada",
    sort: true,
    formatter: columnFormatters.EntrancesTypeColumnFormatter,
    style: { minWidth: 180 },
  },
  {
    dataField: "cf_recibido",
    text: "Recibido",
    sort: true,
  },
  {
    dataField: "cf_no_requerimiento",
    text: "No. de requerimiento",
    sort: true,
  },

  {
    dataField: "entrada_cerrada",
    text: "Estatus",
    sort: true,
    formatter: columnFormatters.EntrancesStatusColumnFormatter,
    style: { minWidth: 110 },
  },
  {
    dataField: "action",
    text: "Acciones",
    formatter: columnFormatters.EntrancesActionsColumnFormatter,
    formatExtraData: {
      onEye,
      onLock,
    },
    classes: "text-right pr-0",
    headerClasses: "text-right pr-3",
    style: {
      minWidth: "100px",
    },
    csvExport: false,
  },
  {
    dataField: "concentrado",
    text: "No. Concentrado",
    sort: true,
    formatter: columnFormatters.EntradaConcentradoColumnFormatter,
    style: { minWidth: 110 },
  },
  {
    dataField: "cf_observaciones",
    text: "Observaciones",
    sort: true,
    style: { minWidth: 110 },
  },
  // {
  //   dataField: "cf_importe_remision_factura",
  //   text: "Importe",
  //   isPrice: true,
  //   formatter: (value) => formatMoney(value),
  //   style: {
  //     whiteSpace: "nowrap",
  //     minWidth: 110,
  //   },
  // },

  // llll-----

  // llll-----
  // {
  //   dataField: "cf_no_requerimiento",
  //   text: "Req. Cerrado",
  //   sort: true,
  // },
  // llll-----

  // {
  //     dataField: 'cf_fecha_entrada',
  //     text: 'Fecha de entrada',
  //     formatter: (value) => inputFormatDate(value),
  //     sort: true,
  // },
  // {
  //   dataField: "proveedor_razon_social",
  //   text: "Proveedor",
  //   sort: true,
  // },
  // {
  //   dataField: "created_at",
  //   text: "Fecha de creación",
  //   formatter: (value) => inputFormatDate(value),
  //   sort: true,
  // },
];

export const stockColumns = ({ onEye, onLock }) => [
  {
    dataField: "id",
    text: "Folio bitácora",
    sort: true,
  },
  {
    dataField: "proveedor_razon_social",
    text: "Proveedor",
    sort: true,
  },
  {
    dataField: "contract_number",
    text: "Contrato",
    sort: true,
  },
  {
    dataField: "cf_fecha_entrada",
    text: "Fecha de entrada",
    formatter: (value) => inputFormatDate(value),
    sort: true,
  },
  {
    dataField: "cf_ord_fecha_registro",
    text: "Fecha de registro",
    formatter: (value) => inputFormatDate(value),
    sort: true,
  },
  {
    dataField: "created_at",
    text: "Fecha de creación",
    formatter: (value) => inputFormatDate(value),
    sort: true,
  },
  {
    dataField: "cf_fecha_factura",
    text: "Fecha de captura",
    formatter: (value) => inputFormatDate(value),
    sort: true,
  },
  {
    dataField: "cf_fecha_remision",
    text: "Fecha de remisión",
    formatter: (value) => inputFormatDate(value),
    sort: true,
  },
  {
    dataField: "tipo_entrada",
    text: "Tipo de entrada",
    sort: true,
    formatter: columnFormatters.EntrancesTypeColumnFormatter,
    style: { minWidth: 180 },
  },
  {
    dataField: "cf_no_requerimiento",
    text: "No. de requerimiento",
    sort: true,
  },
  {
    label: "Fecha sello almacén",
    name: "cf_importe_neto",
    type: "date",
  },
  {
    label: "Importe remisión",
    name: "cf_importe_remision",
    type: "text",
    isPrice: true,
  },
  {
    dataField: "cf_no_factura",
    text: "No. de factura",
    sort: true,
    formatter: (cell) => cell?.trim(),
  },
  {
    dataField: "cf_no_remision",
    text: "No. de remisión",
    sort: true,
  },
  {
    dataField: "cf_observaciones",
    text: "Observaciones",
    sort: true,
    style: { minWidth: 110 },
  },
  {
    dataField: "entrada_cerrada",
    text: "Estatus",
    sort: true,
    formatter: columnFormatters.StockEntrancesStatusColumnFormatter,
    style: { minWidth: 110 },
  },
  {
    dataField: "action",
    text: "Acciones",
    formatter: columnFormatters.EntrancesActionsColumnFormatter,
    formatExtraData: {
      onEye,
      onLock,
    },
    classes: "text-right pr-0",
    headerClasses: "text-right pr-3",
    style: {
      minWidth: "100px",
    },
    csvExport: false,
  },
];

export const defaultFilter = {
  no_entrada: "",
  proveedor: null,
  fecha_entrada: "",
  folio: "",
  tipo_entrada: "",
};

export const entranceFilterInputs = (isEntranceListStockPage) => [
  {
    label: "No de entrada",
    name: "no_entrada",
    type: "text",
  },
  {
    label: "Proveedor",
    name: "proveedor",
    type: "search",
    searchFunction: getProvidersAlmacen,
    width: 200,
  },

  {
    label: "Folio",
    name: "folio",
    type: "text",
  },
  {
    label: "Fecha de entrada",
    name: "fecha_entrada",
    type: "date",
  },
  isEntranceListStockPage && {
    label: "Tipo de entrada",
    name: "tipo_entrada",
    type: "dropdown",
    options: [
      { value: "", text: "Seleccione un tipo" },
      { value: "STOCK", text: "STOCK" },
      { value: "ORDINARIA", text: "ORDINARIA" },
      { value: "RECURRENTE", text: "RECURRENTE" },
    ],
  },
];

export const cardItems = (required, delivered, to_deliver) => [
  {
    title: "Bienes requeridos",
    value: required,
    icon: "/media/svg/icons/Shopping/Box2.svg",
    color: "primary",
  },
  {
    title: "Bienes entregados",
    value: delivered,
    icon: "/media/svg/icons/Shopping/Cart1.svg",
    color: "success",
  },
  {
    title: "Bienes por entregar",
    value: to_deliver,
    icon: "/media/svg/icons/Code/Stop.svg",
    color: "danger",
  },
];

export const defaultEntrance = {
  cf_ord_proveedor: "",
  cf_ord_fecha_registro: "",
  cf_ord_no_contrato: "",
  cf_ord_area_solicitante: "",
  supplier: null,
  contract_number: "",
  requirement: "",
  cf_fecha_entrada: "",
  tipo_entrada: "",
  cf_no_factura: "",
  cf_no_remision: "",
  cf_fol_bita: null,
  ordinaria_descripcion: "",
  requisition_item_details: [],
  warehouse_entry_details: [],
  total_requirement_items_amount: 0,
  total_entry_delivered_items_amount: 0,
  entrada_cerrada: false,
  cf_observaciones: "",
  cf_importe_remision_factura: null,
  attachment: [],
  attachment_factura: {},
  attachment_remision: {},
};

export const formInputs = (
  statusEntrada,
  contracts = [],
  requirements = [],
  disabled,
  type,
  closed,
  isStock,
  isRecurrent,
  isOrdinary,
  isCreateAnEntrance,
  isPrecharge
) => [
  {
    label: "Tipo de entrada",
    name: "tipo_entrada",
    type: "dropdown-chip",
    required: true,
    disabled: disabled || isStock || isRecurrent || isOrdinary,
    options: isStock
      ? [{ value: "STOCK", text: "Stock", color: "primary" }]
      : isRecurrent
      ? [
          {
            value: "RECURRENTE",
            text: "Recurrente",
            color: "danger",
          },
        ]
      : isOrdinary
      ? [
          {
            value: "ORDINARIA",
            text: "Ordinaria",
            color: "info",
          },
        ]
      : [
          {
            value: "",
            text: "Seleccione tipo de entrada",
            color: "dark",
          },
          { value: "STOCK", text: "Stock", color: "primary" },
          {
            value: "RECURRENTE",
            text: "Recurrente",
            color: "danger",
          },
          {
            value: "ORDINARIA",
            text: "Ordinaria",
            color: "info",
          },
          {
            value: "LIMPIEZA",
            text: "Mantenimiento y Limpieza",
            color: "warning",
          },
        ],
  },
  type !== "ORDINARIA"
    ? {
        label: "Proveedor",
        name: "supplier",
        type: "search",
        required: true,
        searchFunction: getProvidersAlmacen,
        disabled, // disable field if entry is in detail page
      }
    : {
        label: "Proveedor",
        name: "cf_ord_proveedor",
        type: "text",
        required: true,
        disabled, // disable field if entry is in detail page
      },
  type === "ORDINARIA" && {
    label: "Contrato",
    name: "cf_ord_no_contrato",
    type: "text",
    required: true,
    disabled, // disable field if entry is in detail page
  },

  type !== "ORDINARIA" && {
    label: "Contrato",
    name: "contract_number",
    type: "dropdown",
    required: true,
    disabled: Boolean(contracts.length === 0) || disabled,
    options: contracts,
  },
  type !== "ORDINARIA" &&
    type !== "LIMPIEZA" && {
      label: "No. De requerimiento",
      name: "requirement",
      type: "dropdown",
      options: requirements,
      required: true,
      disabled: Boolean(requirements.length === 0) || disabled,
    },
  disabled && {
    label: "No. de entrada",
    name: "id",
    type: "text",
    required: true,
    focus: true,
    disabled,
  },

  type === "ORDINARIA" && {
    label: "Fecha de registro",
    name: "cf_ord_fecha_registro",
    type: "date",
    disabled, // disable field if entry is in detail page
  },
  //?-----------------------
  type === "ORDINARIA" && {
    label: "Área solicitante",
    name: "cf_ord_area_solicitante",
    type: "text",
    disabled: false, // disable field if entry is in detail page
  },
  type === "STOCK" && {
    label: "Área solicitante",
    name: "cf_ord_area_solicitante",
    type: "text",
    disabled: true, // disable field if entry is in detail page
  },
  type === "LIMPIEZA" && {
    label: "Área solicitante",
    name: "cf_ord_area_solicitante",
    type: "text",
    disabled: true, // disable field if entry is in detail page
  },
  type === "RECURRENTE" && {
    label: "Área solicitante",
    name: "cf_ord_area_solicitante",
    type: "text",
    disabled: true, // disable field if entry is in detail page
  },
  //?-----------------------
  type !== "ORDINARIA" && {
    label: "Recibido",
    name: "cf_recibido",
    type: "text",
    disabled: true,
  },

  type === "ORDINARIA" && {
    label: "Recibido",
    name: "cf_recibido",
    type: "text",
    disabled: false,
  },

  type !== "ORDINARIA" && {
    label: "Contrato",
    name: "contract_number",
    type: "dropdown",
    required: true,
    disabled: Boolean(contracts.length === 0) || disabled,
    options: contracts,
  },
  //?-----------------------
  {
    label: "Fecha de entrada",
    name: "cf_fecha_entrada",
    type: "date",
    required: true,
    disabled: disabled && !isStock && !isPrecharge, // disable field if entry is in detail page and if is not precharged
  },
  {
    label: "Fecha sello factura",
    name: "cf_fecha_factura",
    type: "date",
    required: true,
    disabled: disabled && !isStock && !isPrecharge, // disable field if entry is in detail page and if is not precharged
  },
  {
    label: "Estado",
    name: "entrada_cerrada",
    type: "dropdown-chip",
    required: true,
    disabled: true, // disable field if entry is in detail page and for now, only E type permitted
    options: [
      statusEntrada === "Afectada/Cerrada"
        ? {
            value: true,
            text: "Afectada/Cerrada",
            color: "danger",
          }
        : statusEntrada === "Cerrada"
        ? {
            value: true,
            text: "Cerrada",
            color: "success",
          }
        : statusEntrada === "Afectada/En proceso"
        ? {
            value: false,
            text: "Afectada/En proceso",
            color: "warning",
          }
        : statusEntrada === "En proceso"
        ? {
            value: false,
            text: "En proceso",
            color: "info",
          }
        : {
            value: true,
            text: "Abierto",
            color: "primary",
          },
    ],
  },
  {
    label: "No. de factura",
    name: "cf_no_factura",
    type: "text",
    required: true,
    disabled: disabled && !isStock && !isPrecharge, // disable field if entry is in detail page and if is not precharged
  },
  disabled && {
    label: "Fecha de creación",
    name: "created_at",
    type: "date",
    required: false,
    // disabled is true if entrance is already created,
    disabled: true,
  },
  {
    label: "No. de remisión",
    name: "cf_no_remision",
    type: "text",
    required: true,
    disabled: disabled && !isStock && !isPrecharge, // disable field if entry is in detail page and if is not precharged
  },
  {
    label: "Fecha de la remisión",
    name: "cf_fecha_remision",
    type: "date",
    required: true,
    disabled: disabled && !isStock && !isPrecharge, // disable field if entry is in detail page and if is not precharged
  },
  isCreateAnEntrance && {
    label: "Importe factura",
    name: "cf_importe_remision_factura",
    type: "text",
    isPrice: true,
    disabled, // disable field if entry is in detail page
  },
  // type !== "RECURRENTE" ||
  // (isCreateAnEntrance && {
  //   label: "Importe",
  //   name: "cf_importe_neto",
  //   type: "text",
  //   isPrice: true,
  //   disabled, // disable field if entry is in detail page
  // }),
];
export const formInputsWithUserPermission = (
  statusEntrada,
  contracts = [],
  requirements = [],
  disabled,
  type,
  closed,
  isStock,
  isRecurrent,
  isOrdinary,
  isRevolvente,
  isGastos,
  isCreateAnEntrance,
  isPrecharge,
  // isANewEntry,
  isStockEntranceAndUserPrecarga = false,
  isStockEntranceAndUserBorrador = false,
  isStockEntranceAndUserCerrar = false,
  isLimpiezaEntranceAndUserPrecarga = false,
  isLimpiezaEntranceAndUserBorrador = false,
  isLimpiezaEntranceAndUserCerrar = false,
  isRecurrenteEntranceAndUserPrecarga = false,
  isRecurrenteEntranceAndUserBorrador = false,
  isRecurrenteEntranceAndUserCerrar = false,
  isOrdinariaEntranceAndUserPrecarga = false,
  isOrdinariaEntranceAndUserBorrador = false,
  isOrdinariaEntranceAndUserCerrar = false,
  isGastosEntranceAndPrecarga = false,
  isGastosEntranceAndCerrar = false,
  isFondoEntranceAndPrecarga = false,
  isFondoEntranceAndCerrar = false
) => {
  return (
    //? isStockEntranceAndUserPrecarga
    isStockEntranceAndUserPrecarga || isRecurrenteEntranceAndUserPrecarga
      ? [
          {
            label: "Tipo de entrada",
            name: "tipo_entrada",
            type: "dropdown-chip",
            required: true,
            disabled:
              disabled ||
              isStock ||
              isRecurrent ||
              isOrdinary ||
              isRevolvente ||
              isGastos,
            options: isStock
              ? [
                  {
                    value: "STOCK",
                    text: "Stock",
                    color: "primary",
                  },
                ]
              : isRecurrent
              ? [
                  {
                    value: "RECURRENTE",
                    text: "Recurrente",
                    color: "danger",
                  },
                ]
              : isOrdinary
              ? [
                  {
                    value: "ORDINARIA",
                    text: "Ordinaria",
                    color: "info",
                  },
                ]
              : isRevolvente
              ? [
                  {
                    value: "FONDOREVOLVENTE",
                    text: "Fondo revolvente",
                    color: "primary",
                  },
                ]
              : isGastos
              ? [
                  {
                    value: "GASTOSCOMPROBAR",
                    text: "Gastos a comprobar",
                    color: "danger",
                  },
                ]
              : [
                  {
                    value: "",
                    text: "Seleccione tipo de entrada",
                    color: "dark",
                  },
                  {
                    value: "STOCK",
                    text: "Stock",
                    color: "primary",
                  },
                  {
                    value: "RECURRENTE",
                    text: "Recurrente",
                    color: "danger",
                  },
                  {
                    value: "ORDINARIA",
                    text: "Ordinaria",
                    color: "info",
                  },
                  {
                    value: "LIMPIEZA",
                    text: "Mantenimiento y Limpieza",
                    color: "warning",
                  },
                  {
                    value: "FONDOREVOLVENTE",
                    text: "Fondo revolvente",
                    color: "primary",
                  },
                  {
                    value: "GASTOSCOMPROBAR",
                    text: "Gastos a comprobar",
                    color: "danger",
                  },
                ],
          },
          isCreateAnEntrance
            ? {
                label: "Proveedor",
                name: "supplier",
                type: "search",
                required: true,
                searchFunction: getProvidersAlmacen,
                disabled: false, // disable field if entry is in detail page
              }
            : {
                label: "Proveedor",
                name: "supplier",
                type: "search",
                required: true,
                searchFunction: getProvidersAlmacen,
                disabled: true, // disable field if entry is in detail page
              },
          isCreateAnEntrance
            ? {
                label: "Contrato",
                name: "contract_number",
                type: "dropdown",
                required: true,
                disabled: Boolean(contracts.length === 0) || false,
                options: contracts,
              }
            : {
                label: "Contrato",
                name: "contract_number",
                type: "dropdown",
                required: true,
                disabled: true,
                options: contracts,
              },
          isCreateAnEntrance
            ? {
                label: "No. De requerimiento",
                name: "requirement",
                type: "dropdown",
                options: requirements,
                required: true,
                disabled: false,
              }
            : {
                label: "No. De requerimiento",
                name: "requirement",
                type: "dropdown",
                options: requirements,
                required: true,
                disabled: true,
              },
          !isCreateAnEntrance && {
            label: "No. de entrada",
            name: "id",
            type: "text",
            required: true,
            focus: true,
            disabled: true,
          },
          {
            label: "Fecha de registro",
            name: "cf_ord_fecha_registro",
            type: "date",
            disabled: closed, // disable field if entry is in detail page
          },
          {
            label: "Área solicitante",
            // placeholder:
            placeholder: "Dirección de Control Patrimonial",
            name: "cf_ord_area_solicitante",
            type: "text",
            disabled: true, // disable field if entry is in detail page
            width: 278,
          },

          {
            label: "Recibido",
            placeholder: "Almacen",
            name: "cf_recibido",
            type: "text",
            disabled: true, // disable field if entry is in detail page
          },

          !isCreateAnEntrance && {
            label: "Fecha de entrada",
            name: "cf_fecha_entrada",
            type: "date",
            required: true,
            disabled: true,
          },
          {
            label: "Estado",
            name: "entrada_cerrada",
            type: "dropdown-chip",
            required: true,
            disabled: closed, // disable field if entry is in detail page and for now, only E type permitted
            options: [
              statusEntrada === "Afectada/Cerrada"
                ? {
                    value: true,
                    text: "Afectada/Cerrada",
                    color: "danger",
                  }
                : statusEntrada === "Cerrada"
                ? {
                    value: true,
                    text: "Cerrada",
                    color: "success",
                  }
                : statusEntrada === "Afectada/En proceso"
                ? {
                    value: false,
                    text: "Afectada/En proceso",
                    color: "warning",
                  }
                : statusEntrada === "En proceso"
                ? {
                    value: false,
                    text: "En proceso",
                    color: "info",
                  }
                : {
                    value: true,
                    text: "Abierto",
                    color: "primary",
                  },
            ],
          },
          closed
            ? {
                label: "No. de factura",
                name: "cf_no_factura",
                type: "text",
                required: true,
                disabled: false,
              }
            : {
                label: "No. de factura",
                name: "cf_no_factura",
                type: "text",
                required: true,
                disabled: closed,
              },

          closed
            ? {
                label: "Fecha sello factura",
                name: "cf_fecha_factura",
                type: "date",
                required: true,
                disabled: false,
              }
            : {
                label: "Fecha sello factura",
                name: "cf_fecha_factura",
                type: "date",
                required: true,
                disabled: closed,
              },

          {
            label: "No. de remisión",
            name: "cf_no_remision",
            type: "text",
            required: true,
            disabled: closed, // disable field if entry is in detail page and if is not precharged
          },
          {
            label: "Fecha de la remisión",
            name: "cf_fecha_remision",
            type: "date",
            required: true,
            disabled: closed,
          },
          {
            label: "Importe factura",
            name: "cf_importe_remision_factura",
            type: "text",
            isPrice: true,
            disabled: closed, // disable field if entry is in detail page
          },
          {
            label: "Factura",
            name: "factura",
            type: "file-auto",
            required: false,
            uppercase: true,
            accept: "application/pdf",
            uploadFunction: addEntryFilePDFAlmacen,
            disabled: false,
          },
          {
            label: "Remisión",
            name: "remision",
            type: "file-auto",
            required: false,
            uppercase: true,
            accept: "application/pdf",
            uploadFunction: addEntryFilePDFAlmacen,
            disabled: false,
          },
        ]
      : //? isStockEntranceAndUserBorrador
      isStockEntranceAndUserBorrador || isRecurrenteEntranceAndUserBorrador
      ? [
          {
            label: "Tipo de entrada",
            name: "tipo_entrada",
            type: "dropdown-chip",
            required: true,
            disabled:
              disabled ||
              isStock ||
              isRecurrent ||
              isOrdinary ||
              isRevolvente ||
              isGastos,
            options: isStock
              ? [
                  {
                    value: "STOCK",
                    text: "Stock",
                    color: "primary",
                  },
                ]
              : isRecurrent
              ? [
                  {
                    value: "RECURRENTE",
                    text: "Recurrente",
                    color: "danger",
                  },
                ]
              : isOrdinary
              ? [
                  {
                    value: "ORDINARIA",
                    text: "Ordinaria",
                    color: "info",
                  },
                ]
              : isRevolvente
              ? [
                  {
                    value: "FONDOREVOLVENTE",
                    text: "Fondo revolvente",
                    color: "primary",
                  },
                ]
              : isGastos
              ? [
                  {
                    value: "GASTOSCOMPROBAR",
                    text: "Gastos a comprobar",
                    color: "danger",
                  },
                ]
              : [
                  {
                    value: "",
                    text: "Seleccione tipo de entrada",
                    color: "dark",
                  },
                  {
                    value: "STOCK",
                    text: "Stock",
                    color: "primary",
                  },

                  {
                    value: "RECURRENTE",
                    text: "Recurrente",
                    color: "danger",
                  },
                  {
                    value: "ORDINARIA",
                    text: "Ordinaria",
                    color: "info",
                  },
                  {
                    value: "LIMPIEZA",
                    text: "Mantenimiento y Limpieza",
                    color: "warning",
                  },
                  {
                    value: "FONDOREVOLVENTE",
                    text: "Fondo revolvente",
                    color: "primary",
                  },
                  {
                    value: "GASTOSCOMPROBAR",
                    text: "Gastos a comprobar",
                    color: "danger",
                  },
                ],
          },
          {
            label: "Proveedor",
            name: "supplier",
            type: "search",
            required: true,
            searchFunction: getProvidersAlmacen,
            disabled: true, // disable field if entry is in detail page
          },

          {
            label: "Contrato",
            name: "contract_number",
            type: "dropdown",
            required: true,
            disabled: true,
            options: contracts,
          },
          {
            label: "No. De requerimiento",
            name: "requirement",
            type: "dropdown",
            options: requirements,
            required: true,
            disabled: true,
          },
          {
            label: "No. de entrada",
            name: "id",
            type: "text",
            required: true,
            focus: true,
            disabled: true,
          },
          {
            label: "Fecha de registro",
            name: "cf_ord_fecha_registro",
            type: "date",
            disabled: true, // disable field if entry is in detail page
          },
          {
            label: "Área solicitante",
            placeholder: "Dirección de Control Patrimonial",
            name: "cf_ord_area_solicitante",
            type: "text",
            disabled: true, // disable field if entry is in detail page
            width: 278,
          },

          {
            label: "Recibido",
            placeholder: "Almacen",
            name: "cf_recibido",
            type: "text",
            disabled: true, // disable field if entry is in detail page
          },
          {
            label: "Fecha de entrada",
            name: "cf_fecha_entrada",
            type: "date",
            required: true,
            disabled: closed,
          },
          {
            label: "Estado",
            name: "entrada_cerrada",
            type: "dropdown-chip",
            required: true,
            disabled: true, // disable field if entry is in detail page and for now, only E type permitted
            options: [
              statusEntrada === "Afectada/Cerrada"
                ? {
                    value: true,
                    text: "Afectada/Cerrada",
                    color: "danger",
                  }
                : statusEntrada === "Cerrada"
                ? {
                    value: true,
                    text: "Cerrada",
                    color: "success",
                  }
                : statusEntrada === "Afectada/En proceso"
                ? {
                    value: false,
                    text: "Afectada/En proceso",
                    color: "warning",
                  }
                : statusEntrada === "En proceso"
                ? {
                    value: false,
                    text: "En proceso",
                    color: "info",
                  }
                : {
                    value: true,
                    text: "Abierto",
                    color: "primary",
                  },
            ],
          },

          closed
            ? {
                label: "No. de factura",
                name: "cf_no_factura",
                type: "text",
                required: true,
                disabled: false,
              }
            : {
                label: "No. de factura",
                name: "cf_no_factura",
                type: "text",
                required: true,
                disabled: true,
              },

          closed
            ? {
                label: "Fecha sello factura",
                name: "cf_fecha_factura",
                type: "date",
                required: true,
                disabled: false,
              }
            : {
                label: "Fecha sello factura",
                name: "cf_fecha_factura",
                type: "date",
                required: true,
                disabled: true,
              },

          {
            label: "No. de remisión",
            name: "cf_no_remision",
            type: "text",
            required: true,
            disabled: true, // disable field if entry is in detail page and if is not precharged
          },
          {
            label: "Fecha de la remisión",
            name: "cf_fecha_remision",
            type: "date",
            required: true,
            disabled: true,
          },
          {
            label: "Importe factura",
            name: "cf_importe_remision_factura",
            type: "text",
            isPrice: true,
            disabled: true, // disable field if entry is in detail page
          },
        ] //? isStockEntranceAndUserCerrar?
      : isStockEntranceAndUserCerrar || isRecurrenteEntranceAndUserCerrar
      ? [
          {
            label: "Tipo de entrada",
            name: "tipo_entrada",
            type: "dropdown-chip",
            required: true,
            disabled:
              disabled ||
              isStock ||
              isRecurrent ||
              isOrdinary ||
              isRevolvente ||
              isGastos,
            options: isStock
              ? [
                  {
                    value: "STOCK",
                    text: "Stock",
                    color: "primary",
                  },
                ]
              : isRecurrent
              ? [
                  {
                    value: "RECURRENTE",
                    text: "Recurrente",
                    color: "danger",
                  },
                ]
              : isOrdinary
              ? [
                  {
                    value: "ORDINARIA",
                    text: "Ordinaria",
                    color: "info",
                  },
                ]
              : isRevolvente
              ? [
                  {
                    value: "FONDOREVOLVENTE",
                    text: "Fondo revolvente",
                    color: "primary",
                  },
                ]
              : isGastos
              ? [
                  {
                    value: "GASTOSCOMPROBAR",
                    text: "Gastos a comprobar",
                    color: "danger",
                  },
                ]
              : [
                  {
                    value: "",
                    text: "Seleccione tipo de entrada",
                    color: "dark",
                  },
                  {
                    value: "STOCK",
                    text: "Stock",
                    color: "primary",
                  },
                  {
                    value: "RECURRENTE",
                    text: "Recurrente",
                    color: "danger",
                  },
                  {
                    value: "ORDINARIA",
                    text: "Ordinaria",
                    color: "info",
                  },
                  {
                    value: "LIMPIEZA",
                    text: "Mantenimiento y Limpieza",
                    color: "warning",
                  },
                  {
                    value: "FONDOREVOLVENTE",
                    text: "Fondo revolvente",
                    color: "primary",
                  },
                  {
                    value: "GASTOSCOMPROBAR",
                    text: "Gastos a comprobar",
                    color: "danger",
                  },
                ],
          },
          isCreateAnEntrance
            ? {
                label: "Proveedor",
                name: "supplier",
                type: "search",
                required: true,
                searchFunction: getProvidersAlmacen,
                disabled: false, // disable field if entry is in detail page
              }
            : {
                label: "Proveedor",
                name: "supplier",
                type: "search",
                required: true,
                searchFunction: getProvidersAlmacen,
                disabled: true, // disable field if entry is in detail page
              },
          type === "ORDINARIA" && {
            label: "Contrato",
            name: "cf_ord_no_contrato",
            type: "text",
            required: true,
            disabled: Boolean(contracts.length === 0) || closed,
            options: contracts,
          },
          isCreateAnEntrance
            ? {
                label: "Contrato",
                name: "contract_number",
                type: "dropdown",
                required: true,
                disabled: Boolean(contracts.length === 0) || false,
                options: contracts,
              }
            : {
                label: "Contrato",
                name: "contract_number",
                type: "dropdown",
                required: true,
                disabled: true,
                options: contracts,
              },
          isCreateAnEntrance
            ? {
                label: "No. De requerimiento",
                name: "requirement",
                type: "dropdown",
                options: requirements,
                required: true,
                disabled: false,
              }
            : {
                label: "No. De requerimiento",
                name: "requirement",
                type: "dropdown",
                options: requirements,
                required: true,
                disabled: true,
              },
          isCreateAnEntrance
            ? {
                label: "Importe remisión",
                name: "cf_importe_remision",
                type: "text",
                isPrice: true,
              }
            : {
                label: "Importe remisión",
                name: "cf_importe_remision",
                type: "text",
                isPrice: true,
                disabled: true,
              },
          isCreateAnEntrance
            ? {
                label: "Fecha sello almacén",
                name: "cf_importe_neto",
                type: "date",
              }
            : {
                label: "Fecha sello almacén",
                name: "cf_importe_neto",
                type: "date",
                disabled: true,
              },

          !isCreateAnEntrance && {
            label: "No. de entrada",
            name: "id",
            type: "text",
            required: true,
            focus: true,
            disabled: true,
          },
          {
            label: "Fecha de registro",
            name: "cf_ord_fecha_registro",
            type: "date",
            disabled: closed, // disable field if entry is in detail page
          },

          {
            label: "Área solicitante",
            // placeholder:
            placeholder: "Dirección de Control Patrimonial",
            name: "cf_ord_area_solicitante",
            type: "text",
            disabled: true, // disable field if entry is in detail page
            width: 278,
          },

          {
            label: "Recibido",
            placeholder: "Almacen",
            name: "cf_recibido",
            type: "text",
            disabled: true, // disable field if entry is in detail page
          },
          {
            label: "Fecha de entrada",
            name: "cf_fecha_entrada",
            type: "date",
            required: true,
            disabled: closed,
          },
          {
            label: "Estado",
            name: "entrada_cerrada",
            type: "dropdown-chip",
            required: true,
            disabled: closed, // disable field if entry is in detail page and for now, only E type permitted
            options: [
              statusEntrada === "Afectada/Cerrada"
                ? {
                    value: true,
                    text: "Afectada/Cerrada",
                    color: "danger",
                  }
                : statusEntrada === "Cerrada"
                ? {
                    value: true,
                    text: "Cerrada",
                    color: "success",
                  }
                : statusEntrada === "Afectada/En proceso"
                ? {
                    value: false,
                    text: "Afectada/En proceso",
                    color: "warning",
                  }
                : statusEntrada === "En proceso"
                ? {
                    value: false,
                    text: "En proceso",
                    color: "info",
                  }
                : {
                    value: true,
                    text: "Abierto",
                    color: "primary",
                  },
            ],
          },

          closed
            ? {
                label: "No. de factura",
                name: "cf_no_factura",
                type: "text",
                required: true,
                disabled: false,
              }
            : {
                label: "No. de factura",
                name: "cf_no_factura",
                type: "text",
                required: true,
                disabled: closed,
              },

          closed
            ? {
                label: "Fecha sello factura",
                name: "cf_fecha_factura",
                type: "date",
                required: true,
                disabled: false,
              }
            : {
                label: "Fecha sello factura",
                name: "cf_fecha_factura",
                type: "date",
                required: true,
                disabled: closed,
              },
          // {
          //   label: "Fecha de factura",
          //   name: "cf_fecha_factura",
          //   type: "date",
          //   required: true,
          //   disabled: closed, // disable field if entry is in detail page and if is not precharged
          // },

          // {
          //   label: "No. de factura",
          //   name: "cf_no_factura",
          //   type: "text",
          //   required: true,
          //   disabled: closed, // disable field if entry is in detail page and if is not precharged
          // },
          // {
          //   label: "Fecha de creación",
          //   name: "created_at",
          //   type: "date",
          //   required: false,
          //   disabled: closed,
          // },
          {
            label: "No. de remisión",
            name: "cf_no_remision",
            type: "text",
            required: true,
            disabled: closed, // disable field if entry is in detail page and if is not precharged
          },
          {
            label: "Fecha de la remisión",
            name: "cf_fecha_remision",
            type: "date",
            required: true,
            disabled: closed,
          },
          {
            label: "Importe remisión",
            name: "cf_importe_remision_factura",
            type: "text",
            isPrice: true,
            disabled: closed, // disable field if entry is in detail page
          },
          {
            label: "Factura",
            name: "factura",
            type: "file-auto",
            required: false,
            uppercase: true,
            accept: "application/pdf",
            uploadFunction: addEntryFilePDFAlmacen,
            disabled: false,
          },
          {
            label: "Remisión",
            name: "remision",
            type: "file-auto",
            required: false,
            uppercase: true,
            accept: "application/pdf",
            uploadFunction: addEntryFilePDFAlmacen,
            disabled: false,
          },
        ]
      : //?LIMPIEZA
      isLimpiezaEntranceAndUserPrecarga
      ? [
          {
            dataField: "cf_no_remision",
            text: "No. de remisión",
            sort: true,
          },
          {
            dataField: "cf_fecha_remision",
            text: "Fecha de remisión",
            formatter: (value) => inputFormatDate(value),
            sort: true,
          },
          {
            label: "Tipo de entrada",
            name: "tipo_entrada",
            type: "dropdown-chip",
            required: true,
            disabled:
              disabled ||
              isStock ||
              isRecurrent ||
              isOrdinary ||
              isRevolvente ||
              isGastos,
            options: isStock
              ? [
                  {
                    value: "STOCK",
                    text: "Stock",
                    color: "primary",
                  },
                ]
              : isRecurrent
              ? [
                  {
                    value: "RECURRENTE",
                    text: "Recurrente",
                    color: "danger",
                  },
                ]
              : isOrdinary
              ? [
                  {
                    value: "ORDINARIA",
                    text: "Ordinaria",
                    color: "info",
                  },
                ]
              : isRevolvente
              ? [
                  {
                    value: "FONDOREVOLVENTE",
                    text: "Fondo revolvente",
                    color: "primary",
                  },
                ]
              : isGastos
              ? [
                  {
                    value: "GASTOSCOMPROBAR",
                    text: "Gastos a comprobar",
                    color: "danger",
                  },
                ]
              : [
                  {
                    value: "",
                    text: "Seleccione tipo de entrada",
                    color: "dark",
                  },
                  {
                    value: "STOCK",
                    text: "Stock",
                    color: "primary",
                  },
                  {
                    value: "RECURRENTE",
                    text: "Recurrente",
                    color: "danger",
                  },
                  {
                    value: "ORDINARIA",
                    text: "Ordinaria",
                    color: "info",
                  },
                  {
                    value: "LIMPIEZA",
                    text: "Mantenimiento y Limpieza",
                    color: "warning",
                  },
                  {
                    value: "FONDOREVOLVENTE",
                    text: "Fondo revolvente",
                    color: "primary",
                  },
                  {
                    value: "GASTOSCOMPROBAR",
                    text: "Gastos a comprobar",
                    color: "danger",
                  },
                ],
          },
          {
            label: "Proveedor",
            name: "supplier",
            type: "search",
            required: true,
            searchFunction: getProvidersAlmacen,
            disabled: closed, // disable field if entry is in detail page
          },
          {
            label: "Contrato",
            name: "contract_number",
            type: "dropdown",
            required: true,
            disabled: Boolean(contracts.length === 0) || disabled,
            options: contracts,
          },
          {
            label: "Fecha de entrada",
            name: "cf_fecha_entrada",
            type: "date",
            required: true,
            disabled: closed,
          },
          closed
            ? {
                label: "No. de factura",
                name: "cf_no_factura",
                type: "text",
                required: true,
                disabled: false,
              }
            : {
                label: "No. de factura",
                name: "cf_no_factura",
                type: "text",
                required: true,
                disabled: closed,
              },

          closed
            ? {
                label: "Fecha sello factura",
                name: "cf_fecha_factura",
                type: "date",
                required: true,
                disabled: false,
              }
            : {
                label: "Fecha sello factura",
                name: "cf_fecha_factura",
                type: "date",
                required: true,
                disabled: closed,
              },

          {
            label: "No. de remisión",
            name: "cf_no_remision",
            type: "text",
            required: true,
            disabled: closed, // disable field if entry is in detail page and if is not precharged
          },
          {
            label: "Fecha de la remisión",
            name: "cf_fecha_remision",
            type: "date",
            required: true,
            disabled: closed,
          },
          {
            label: "No. de remisión",
            name: "cf_no_remision",
            type: "text",
            required: true,
            disabled: closed,
          },
          {
            label: "Recibido",
            placeholder: "Almacen",
            name: "cf_recibido",
            type: "text",
            disabled: true, // disable field if entry is in detail page
          },
          {
            label: "Fecha sello almacén",
            name: "cf_importe_neto",
            type: "date",
          },
          {
            label: "Importe remisión",
            name: "cf_importe_remision",
            type: "text",
            isPrice: true,
          },
          {
            label: "Área solicitante",
            placeholder: "Dirección de mantenimiento de bienes muebles e inmuebles",
            name: "cf_ord_area_solicitante",
            type: "text",
            disabled: true, // disable field if entry is in detail page
            width: 440,
          },

          {
            label: "Estado",
            name: "entrada_cerrada",
            type: "dropdown-chip",
            required: true,
            disabled: closed, // disable field if entry is in detail page and for now, only E type permitted
            options: [
              statusEntrada === "Afectada/Cerrada"
                ? {
                    value: true,
                    text: "Afectada/Cerrada",
                    color: "danger",
                  }
                : statusEntrada === "Cerrada"
                ? {
                    value: true,
                    text: "Cerrada",
                    color: "success",
                  }
                : statusEntrada === "Afectada/En proceso"
                ? {
                    value: false,
                    text: "Afectada/En proceso",
                    color: "warning",
                  }
                : statusEntrada === "En proceso"
                ? {
                    value: false,
                    text: "En proceso",
                    color: "info",
                  }
                : {
                    value: true,
                    text: "Abierto",
                    color: "primary",
                  },
            ],
          },
          {
            label: "Importe",
            name: "cf_importe_neto",
            type: "text",
            isPrice: true,
            disabled: closed, // disable field if entry is in detail page
          },
          {
            label: "Factura",
            name: "factura",
            type: "file-auto",
            required: false,
            uppercase: true,
            accept: "application/pdf",
            uploadFunction: addEntryFilePDFAlmacen,
            disabled: false,
          },
          {
            label: "Remisión",
            name: "remision",
            type: "file-auto",
            required: false,
            uppercase: true,
            accept: "application/pdf",
            uploadFunction: addEntryFilePDFAlmacen,
            disabled: false,
          },
        ]
      : isLimpiezaEntranceAndUserBorrador
      ? [
          {
            dataField: "cf_no_remision",
            text: "No. de remisión",
            sort: true,
          },
          {
            dataField: "cf_fecha_remision",
            text: "Fecha de remisión",
            formatter: (value) => inputFormatDate(value),
            sort: true,
          },
          {
            label: "Tipo de entrada",
            name: "tipo_entrada",
            type: "dropdown-chip",
            required: true,
            disabled:
              disabled ||
              isStock ||
              isRecurrent ||
              isOrdinary ||
              isRevolvente ||
              isGastos,
            options: isStock
              ? [
                  {
                    value: "STOCK",
                    text: "Stock",
                    color: "primary",
                  },
                ]
              : isRecurrent
              ? [
                  {
                    value: "RECURRENTE",
                    text: "Recurrente",
                    color: "danger",
                  },
                ]
              : isOrdinary
              ? [
                  {
                    value: "ORDINARIA",
                    text: "Ordinaria",
                    color: "info",
                  },
                ]
              : isRevolvente
              ? [
                  {
                    value: "FONDOREVOLVENTE",
                    text: "Fondo revolvente",
                    color: "primary",
                  },
                ]
              : isGastos
              ? [
                  {
                    value: "GASTOSCOMPROBAR",
                    text: "Gastos a comprobar",
                    color: "danger",
                  },
                ]
              : [
                  {
                    value: "",
                    text: "Seleccione tipo de entrada",
                    color: "dark",
                  },
                  {
                    value: "STOCK",
                    text: "Stock",
                    color: "primary",
                  },
                  {
                    value: "RECURRENTE",
                    text: "Recurrente",
                    color: "danger",
                  },
                  {
                    value: "ORDINARIA",
                    text: "Ordinaria",
                    color: "info",
                  },
                  {
                    value: "LIMPIEZA",
                    text: "Mantenimiento y Limpieza",
                    color: "warning",
                  },
                  {
                    value: "FONDOREVOLVENTE",
                    text: "Fondo revolvente",
                    color: "primary",
                  },
                  {
                    value: "GASTOSCOMPROBAR",
                    text: "Gastos a comprobar",
                    color: "danger",
                  },
                ],
          },
          {
            label: "Proveedor",
            name: "supplier",
            type: "search",
            required: true,
            searchFunction: getProvidersAlmacen,
            disabled: true, // disable field if entry is in detail page
          },

          closed
            ? {
                label: "No. de factura",
                name: "cf_no_factura",
                type: "text",
                required: true,
                disabled: false,
              }
            : {
                label: "No. de factura",
                name: "cf_no_factura",
                type: "text",
                required: true,
                disabled: true,
              },

          closed
            ? {
                label: "Fecha sello factura",
                name: "cf_fecha_factura",
                type: "date",
                required: true,
                disabled: false,
              }
            : {
                label: "Fecha sello factura",
                name: "cf_fecha_factura",
                type: "date",
                required: true,
                disabled: true,
              },
          // {
          //   label: "Fecha de factura",
          //   name: "cf_fecha_factura",
          //   type: "date",
          //   required: true,
          //   disabled: true, // disable field if entry is in detail page and if is not precharged
          // },
          // {
          //   label: "No. de factura",
          //   name: "cf_no_factura",
          //   type: "text",
          //   required: true,
          //   disabled: true, // disable field if entry is in detail page and if is not precharged
          // },
          {
            label: "No. de remisión",
            name: "cf_no_remision",
            type: "text",
            required: true,
            disabled: true, // disable field if entry is in detail page and if is not precharged
          },
          {
            label: "Fecha de la remisión",
            name: "cf_fecha_remision",
            type: "date",
            required: true,
            disabled: true,
          },

          {
            label: "No. de remisión",
            name: "cf_no_remision",
            type: "text",
            required: true,
            disabled: closed,
          },
          {
            label: "Recibido",
            placeholder: "Almacen",
            name: "cf_recibido",
            type: "text",
            disabled: true, // disable field if entry is in detail page
          },
          {
            label: "Área solicitante",
            placeholder: "Dirección de mantenimiento de bienes muebles e inmuebles",
            name: "cf_ord_area_solicitante",
            type: "text",
            disabled: true, // disable field if entry is in detail page
            width: 295,
          },
          {
            label: "Fecha de entrada",
            name: "cf_fecha_entrada",
            type: "date",
            required: true,
            disabled: closed,
          },
          {
            label: "Estado",
            name: "entrada_cerrada",
            type: "dropdown-chip",
            required: true,
            disabled: closed, // disable field if entry is in detail page and for now, only E type permitted
            options: [
              statusEntrada === "Afectada/Cerrada"
                ? {
                    value: true,
                    text: "Afectada/Cerrada",
                    color: "danger",
                  }
                : statusEntrada === "Cerrada"
                ? {
                    value: true,
                    text: "Cerrada",
                    color: "success",
                  }
                : statusEntrada === "Afectada/En proceso"
                ? {
                    value: false,
                    text: "Afectada/En proceso",
                    color: "warning",
                  }
                : statusEntrada === "En proceso"
                ? {
                    value: false,
                    text: "En proceso",
                    color: "info",
                  }
                : {
                    value: true,
                    text: "Abierto",
                    color: "primary",
                  },
            ],
          },

          // {
          //   label: "Fecha de creación",
          //   name: "created_at",
          //   type: "date",
          //   required: false,
          //   disabled: closed,
          // },

          {
            label: "Importe",
            name: "cf_importe_neto",
            type: "text",
            isPrice: true,
            disabled: closed, // disable field if entry is in detail page
          },
        ]
      : isLimpiezaEntranceAndUserCerrar
      ? [
          {
            label: "Tipo de entrada",
            name: "tipo_entrada",
            type: "dropdown-chip",
            required: true,
            disabled:
              disabled ||
              isStock ||
              isRecurrent ||
              isOrdinary ||
              isRevolvente ||
              isGastos,
            options: isStock
              ? [
                  {
                    value: "STOCK",
                    text: "Stock",
                    color: "primary",
                  },
                ]
              : isRecurrent
              ? [
                  {
                    value: "RECURRENTE",
                    text: "Recurrente",
                    color: "danger",
                  },
                ]
              : isOrdinary
              ? [
                  {
                    value: "ORDINARIA",
                    text: "Ordinaria",
                    color: "info",
                  },
                ]
              : isRevolvente
              ? [
                  {
                    value: "FONDOREVOLVENTE",
                    text: "Fondo revolvente",
                    color: "primary",
                  },
                ]
              : isGastos
              ? [
                  {
                    value: "GASTOSCOMPROBAR",
                    text: "Gastos a comprobar",
                    color: "danger",
                  },
                ]
              : [
                  {
                    value: "",
                    text: "Seleccione tipo de entrada",
                    color: "dark",
                  },
                  {
                    value: "STOCK",
                    text: "Stock",
                    color: "primary",
                  },
                  {
                    value: "RECURRENTE",
                    text: "Recurrente",
                    color: "danger",
                  },
                  {
                    value: "ORDINARIA",
                    text: "Ordinaria",
                    color: "info",
                  },
                  {
                    value: "LIMPIEZA",
                    text: "Mantenimiento y Limpieza",
                    color: "warning",
                  },
                  {
                    value: "FONDOREVOLVENTE",
                    text: "Fondo revolvente",
                    color: "primary",
                  },
                  {
                    value: "GASTOSCOMPROBAR",
                    text: "Gastos a comprobar",
                    color: "danger",
                  },
                ],
          },
          {
            label: "Contrato",
            name: "cf_ord_no_contrato",
            type: "text",
            required: true,
            disabled: false,
            disabled: closed,
          },
          {
            label: "Fecha sello almacén",
            name: "cf_importe_neto",
            type: "date",
          },
          {
            label: "Importe remisión",
            name: "cf_importe_remision",
            type: "text",
            isPrice: true,
          },
          {
            label: "Proveedor",
            name: "supplier",
            type: "search",
            required: true,
            searchFunction: getProvidersAlmacen,
            disabled: closed, // disable field if entry is in detail page
          },
          closed
            ? {
                label: "No. de factura",
                name: "cf_no_factura",
                type: "text",
                required: true,
                disabled: false,
              }
            : {
                label: "No. de factura",
                name: "cf_no_factura",
                type: "text",
                required: true,
                disabled: closed,
              },

          closed
            ? {
                label: "Fecha sello factura",
                name: "cf_fecha_factura",
                type: "date",
                required: true,
                disabled: false,
              }
            : {
                label: "Fecha sello factura",
                name: "cf_fecha_factura",
                type: "date",
                required: true,
                disabled: closed,
              },
          // {
          //   label: "No. de factura",
          //   name: "cf_no_factura",
          //   type: "text",
          //   required: true,
          //   disabled: closed, // disable field if entry is in detail page and if is not precharged
          // },
          // {
          //   label: "No. de remisión",
          //   name: "cf_no_remision",
          //   type: "text",
          //   required: true,
          //   disabled: closed, // disable field if entry is in detail page and if is not precharged
          // },
          {
            label: "Fecha de la remisión",
            name: "cf_fecha_remision",
            type: "date",
            required: true,
            disabled: closed,
          },
          {
            label: "No. de remisión",
            name: "cf_no_remision",
            type: "text",
            required: true,
            disabled: closed,
          },
          {
            label: "Recibido",
            placeholder: "Almacen",
            name: "cf_recibido",
            type: "text",
            disabled: true, // disable field if entry is in detail page
          },
          {
            label: "Área solicitante",
            placeholder: "Dirección de mantenimiento de bienes muebles e inmuebles",
            name: "cf_ord_area_solicitante",
            type: "text",
            disabled: true, // disable field if entry is in detail page
            width: 278,
          },
          {
            label: "Fecha de entrada",
            name: "cf_fecha_entrada",
            type: "date",
            required: true,
            disabled: closed,
          },
          {
            label: "Estado",
            name: "entrada_cerrada",
            type: "dropdown-chip",
            required: true,
            disabled: closed, // disable field if entry is in detail page and for now, only E type permitted
            options: [
              statusEntrada === "Afectada/Cerrada"
                ? {
                    value: true,
                    text: "Afectada/Cerrada",
                    color: "danger",
                  }
                : statusEntrada === "Cerrada"
                ? {
                    value: true,
                    text: "Cerrada",
                    color: "success",
                  }
                : statusEntrada === "Afectada/En proceso"
                ? {
                    value: false,
                    text: "Afectada/En proceso",
                    color: "warning",
                  }
                : statusEntrada === "En proceso"
                ? {
                    value: false,
                    text: "En proceso",
                    color: "info",
                  }
                : {
                    value: true,
                    text: "Abierto",
                    color: "primary",
                  },
            ],
          },
          {
            label: "Importe",
            name: "cf_importe_neto",
            type: "text",
            isPrice: true,
            disabled: closed, // disable field if entry is in detail page
          },
          {
            label: "Factura",
            name: "factura",
            type: "file-auto",
            required: false,
            uppercase: true,
            accept: "application/pdf",
            uploadFunction: addEntryFilePDFAlmacen,
            disabled: false,
          },
          {
            label: "Remisión",
            name: "remision",
            type: "file-auto",
            required: false,
            uppercase: true,
            accept: "application/pdf",
            uploadFunction: addEntryFilePDFAlmacen,
            disabled: false,
          },
        ]
      : //? ORDIONARIA
      isOrdinariaEntranceAndUserPrecarga
      ? [
          {
            label: "Tipo de entrada",
            name: "tipo_entrada",
            type: "dropdown-chip",
            required: true,
            disabled: disabled || isStock || isRecurrent || isOrdinary,
            options: isStock
              ? [
                  {
                    value: "STOCK",
                    text: "Stock",
                    color: "primary",
                  },
                ]
              : isRecurrent
              ? [
                  {
                    value: "RECURRENTE",
                    text: "Recurrente",
                    color: "danger",
                  },
                ]
              : isOrdinary
              ? [
                  {
                    value: "ORDINARIA",
                    text: "Ordinaria",
                    color: "info",
                  },
                ]
              : [
                  {
                    value: "",
                    text: "Seleccione tipo de entrada",
                    color: "dark",
                  },
                  {
                    value: "STOCK",
                    text: "Stock",
                    color: "primary",
                  },
                  {
                    value: "RECURRENTE",
                    text: "Recurrente",
                    color: "danger",
                  },
                  {
                    value: "ORDINARIA",
                    text: "Ordinaria",
                    color: "info",
                  },
                  {
                    value: "LIMPIEZA",
                    text: "Mantenimiento y Limpieza",
                    color: "warning",
                  },
                ],
          },
          isCreateAnEntrance
            ? {
                label: "Proveedor",
                name: "cf_ord_proveedor",
                type: "text",
                required: true,
                disabled, // disable field if entry is in detail page
              }
            : {
                label: "Proveedor",
                name: "cf_ord_proveedor",
                type: "text",
                required: true,
                disabled: closed, // disable field if entry is in detail page
              },
          isCreateAnEntrance
            ? {
                label: "Contrato",
                name: "cf_ord_no_contrato",
                type: "text",
                required: true,
                disabled,
              }
            : {
                label: "Contrato",
                name: "cf_ord_no_contrato",
                type: "text",
                required: true,
                disabled: closed,
              },

          !isCreateAnEntrance && {
            label: "No. de entrada",
            name: "id",
            type: "text",
            required: true,
            focus: true,
            disabled: true,
          },
          {
            label: "Fecha de registro",
            name: "cf_ord_fecha_registro",
            type: "date",
            disabled: closed, // disable field if entry is in detail page
          },

          isCreateAnEntrance
            ? {
                label: "Área solicitante",
                name: "cf_ord_area_solicitante",
                type: "text",
                disabled: false,
              }
            : {
                label: "Área solicitante",
                name: "cf_ord_area_solicitante",
                type: "text",
                disabled: closed,
              },
          isCreateAnEntrance
            ? {
                label: "Recibido",
                name: "cf_recibido",
                type: "text",
                disabled: false, // disable field if entry is in detail page
              }
            : {
                label: "Recibido",
                name: "cf_recibido",
                type: "text",
                disabled: closed, // disable field if entry is in detail page
              },
          isCreateAnEntrance
            ? {
                label: "Fecha de entrada",
                name: "cf_fecha_entrada",
                type: "date",
                required: true,
                disabled: false,
              }
            : {
                label: "Fecha de entrada",
                name: "cf_fecha_entrada",
                type: "date",
                required: true,
                disabled: true,
              },

          {
            label: "Estado",
            name: "entrada_cerrada",
            type: "dropdown-chip",
            required: true,
            disabled: closed, // disable field if entry is in detail page and for now, only E type permitted
            options: [
              statusEntrada === "Afectada/Cerrada"
                ? {
                    value: true,
                    text: "Afectada/Cerrada",
                    color: "danger",
                  }
                : statusEntrada === "Cerrada"
                ? {
                    value: true,
                    text: "Cerrada",
                    color: "success",
                  }
                : statusEntrada === "Afectada/En proceso"
                ? {
                    value: false,
                    text: "Afectada/En proceso",
                    color: "warning",
                  }
                : statusEntrada === "En proceso"
                ? {
                    value: false,
                    text: "En proceso",
                    color: "info",
                  }
                : {
                    value: true,
                    text: "Abierto",
                    color: "primary",
                  },
            ],
          },
          closed
            ? {
                label: "No. de factura",
                name: "cf_no_factura",
                type: "text",
                required: true,
                disabled: false,
              }
            : {
                label: "No. de factura",
                name: "cf_no_factura",
                type: "text",
                required: true,
                disabled: closed,
              },

          closed
            ? {
                label: "Fecha sello factura",
                name: "cf_fecha_factura",
                type: "date",
                required: true,
                disabled: false,
              }
            : {
                label: "Fecha sello factura",
                name: "cf_fecha_factura",
                type: "date",
                required: true,
                disabled: closed,
              },
          // {
          //   label: "No. de factura",
          //   name: "cf_no_factura",
          //   type: "text",
          //   required: true,
          //   disabled: closed, // disable field if entry is in detail page and if is not precharged
          // },
          // {
          //   label: "Fecha de factura",
          //   name: "cf_fecha_factura",
          //   type: "date",
          //   required: true,
          //   disabled: closed, // disable field if entry is in detail page and if is not precharged
          // },

          {
            label: "No. de remisión",
            name: "cf_no_remision",
            type: "text",
            required: true,
            disabled: closed, // disable field if entry is in detail page and if is not precharged
          },
          {
            label: "Fecha de la remisión",
            name: "cf_fecha_remision",
            type: "date",
            required: true,
            disabled: closed,
          },

          {
            label: "Importe",
            name: "cf_importe_neto",
            type: "text",
            isPrice: true,
            disabled: closed, // disable field if entry is in detail page
          },
          {
            label: "Factura",
            name: "factura",
            type: "file-auto",
            required: false,
            uppercase: true,
            accept: "application/pdf",
            uploadFunction: addEntryFilePDFAlmacen,
            disabled: false,
          },
          {
            label: "Remisión",
            name: "remision",
            type: "file-auto",
            required: false,
            uppercase: true,
            accept: "application/pdf",
            uploadFunction: addEntryFilePDFAlmacen,
            disabled: false,
          },
        ]
      : isOrdinariaEntranceAndUserBorrador
      ? [
          {
            label: "Tipo de entrada",
            name: "tipo_entrada",
            type: "dropdown-chip",
            required: true,
            disabled: disabled || isStock || isRecurrent || isOrdinary,
            options: isStock
              ? [
                  {
                    value: "STOCK",
                    text: "Stock",
                    color: "primary",
                  },
                ]
              : isRecurrent
              ? [
                  {
                    value: "RECURRENTE",
                    text: "Recurrente",
                    color: "danger",
                  },
                ]
              : isOrdinary
              ? [
                  {
                    value: "ORDINARIA",
                    text: "Ordinaria",
                    color: "info",
                  },
                ]
              : [
                  {
                    value: "",
                    text: "Seleccione tipo de entrada",
                    color: "dark",
                  },
                  {
                    value: "STOCK",
                    text: "Stock",
                    color: "primary",
                  },
                  {
                    value: "RECURRENTE",
                    text: "Recurrente",
                    color: "danger",
                  },
                  {
                    value: "ORDINARIA",
                    text: "Ordinaria",
                    color: "info",
                  },
                  {
                    value: "LIMPIEZA",
                    text: "Mantenimiento y Limpieza",
                    color: "warning",
                  },
                ],
          },
          {
            label: "Proveedor",
            name: "cf_ord_proveedor",
            type: "text",
            required: true,
            disabled, // disable field if entry is in detail page
          },

          {
            label: "Contrato",
            name: "cf_ord_no_contrato",
            type: "text",
            required: true,
            disabled,
          },
          {
            label: "No. de entrada",
            name: "id",
            type: "text",
            required: true,
            focus: true,
            disabled: true,
          },
          {
            label: "Fecha de registro",
            name: "cf_ord_fecha_registro",
            type: "date",
            disabled: true, // disable field if entry is in detail page
          },
          {
            label: "Área solicitante",
            name: "cf_ord_area_solicitante",
            type: "text",
            disabled: true,
          },
          {
            label: "Recibido",
            name: "cf_recibido",
            type: "text",
            disabled: true, // disable field if entry is in detail page
          },
          {
            label: "Fecha de entrada",
            name: "cf_fecha_entrada",
            type: "date",
            required: true,
            disabled: true,
          },

          {
            label: "Estado",
            name: "entrada_cerrada",
            type: "dropdown-chip",
            required: true,
            disabled: true, // disable field if entry is in detail page and for now, only E type permitted
            options: [
              statusEntrada === "Afectada/Cerrada"
                ? {
                    value: true,
                    text: "Afectada/Cerrada",
                    color: "danger",
                  }
                : statusEntrada === "Cerrada"
                ? {
                    value: true,
                    text: "Cerrada",
                    color: "success",
                  }
                : statusEntrada === "Afectada/En proceso"
                ? {
                    value: false,
                    text: "Afectada/En proceso",
                    color: "warning",
                  }
                : statusEntrada === "En proceso"
                ? {
                    value: false,
                    text: "En proceso",
                    color: "info",
                  }
                : {
                    value: true,
                    text: "Abierto",
                    color: "primary",
                  },
            ],
          },
          closed
            ? {
                label: "No. de factura",
                name: "cf_no_factura",
                type: "text",
                required: true,
                disabled: false,
              }
            : {
                label: "No. de factura",
                name: "cf_no_factura",
                type: "text",
                required: true,
                disabled: true,
              },

          closed
            ? {
                label: "Fecha sello factura",
                name: "cf_fecha_factura",
                type: "date",
                required: true,
                disabled: false,
              }
            : {
                label: "Fecha sello factura",
                name: "cf_fecha_factura",
                type: "date",
                required: true,
                disabled: true,
              },
          // {
          //   label: "No. de factura",
          //   name: "cf_no_factura",
          //   type: "text",
          //   required: true,
          //   disabled: true, // disable field if entry is in detail page and if is not precharged
          // },
          // {
          //   label: "Fecha de factura",
          //   name: "cf_fecha_factura",
          //   type: "date",
          //   required: true,
          //   disabled: true, // disable field if entry is in detail page and if is not precharged
          // },

          {
            label: "No. de remisión",
            name: "cf_no_remision",
            type: "text",
            required: true,
            disabled: true, // disable field if entry is in detail page and if is not precharged
          },
          {
            label: "Fecha de la remisión",
            name: "cf_fecha_remision",
            type: "date",
            required: true,
            disabled: true,
          },

          {
            label: "Importe",
            name: "cf_importe_neto",
            type: "text",
            isPrice: true,
            disabled: true, // disable field if entry is in detail page
          },
        ]
      : isOrdinariaEntranceAndUserCerrar
      ? [
          {
            label: "Tipo de entrada",
            name: "tipo_entrada",
            type: "dropdown-chip",
            required: true,
            disabled: disabled || isStock || isRecurrent || isOrdinary,
            options: isStock
              ? [
                  {
                    value: "STOCK",
                    text: "Stock",
                    color: "primary",
                  },
                ]
              : isRecurrent
              ? [
                  {
                    value: "RECURRENTE",
                    text: "Recurrente",
                    color: "danger",
                  },
                ]
              : isOrdinary
              ? [
                  {
                    value: "ORDINARIA",
                    text: "Ordinaria",
                    color: "info",
                  },
                ]
              : [
                  {
                    value: "",
                    text: "Seleccione tipo de entrada",
                    color: "dark",
                  },
                  {
                    value: "STOCK",
                    text: "Stock",
                    color: "primary",
                  },
                  {
                    value: "RECURRENTE",
                    text: "Recurrente",
                    color: "danger",
                  },
                  {
                    value: "ORDINARIA",
                    text: "Ordinaria",
                    color: "info",
                  },
                  {
                    value: "LIMPIEZA",
                    text: "Mantenimiento y Limpieza",
                    color: "warning",
                  },
                ],
          },
          isCreateAnEntrance
            ? {
                label: "Proveedor",
                name: "cf_ord_proveedor",
                type: "text",
                required: true,
                disabled, // disable field if entry is in detail page
              }
            : {
                label: "Proveedor",
                name: "cf_ord_proveedor",
                type: "text",
                required: true,
                disabled: true, // disable field if entry is in detail page
              },

          isCreateAnEntrance
            ? {
                label: "Importe remisión",
                name: "cf_importe_remision",
                type: "text",
                isPrice: true,
              }
            : {
                label: "Importe remisión",
                name: "cf_importe_remision",
                type: "text",
                isPrice: true,
                disabled: true,
              },
          isCreateAnEntrance
            ? {
                label: "Fecha sello almacén",
                name: "cf_importe_neto",
                type: "date",
              }
            : {
                label: "Fecha sello almacén",
                name: "cf_importe_neto",
                type: "date",
                disabled: true,
              },
          isCreateAnEntrance
            ? {
                label: "Contrato",
                name: "cf_ord_no_contrato",
                type: "text",
                required: true,
                disabled,
              }
            : {
                label: "Contrato",
                name: "cf_ord_no_contrato",
                type: "text",
                required: true,
                disabled: true,
              },

          !isCreateAnEntrance && {
            label: "No. de entrada",
            name: "id",
            type: "text",
            required: true,
            focus: true,
            disabled: true,
          },
          isCreateAnEntrance
            ? {
                label: "Fecha de registro",
                name: "cf_ord_fecha_registro",
                type: "date",
                disabled: false, // disable field if entry is in detail page
              }
            : {
                label: "Fecha de registro",
                name: "cf_ord_fecha_registro",
                type: "date",
                disabled: true, // disable field if entry is in detail page
              },

          isCreateAnEntrance
            ? {
                label: "Área solicitante",
                name: "cf_ord_area_solicitante",
                type: "text",
                disabled: false,
              }
            : {
                label: "Área solicitante",
                name: "cf_ord_area_solicitante",
                type: "text",
                disabled: true,
              },
          isCreateAnEntrance
            ? {
                label: "Recibido",
                name: "cf_recibido",
                type: "text",
                disabled: false, // disable field if entry is in detail page
              }
            : {
                label: "Recibido",
                name: "cf_recibido",
                type: "text",
                disabled: true, // disable field if entry is in detail page
              },
          {
            label: "Fecha de entrada",
            name: "cf_fecha_entrada",
            type: "date",
            required: true,
            disabled: closed,
          },

          {
            label: "Estado",
            name: "entrada_cerrada",
            type: "dropdown-chip",
            required: true,
            disabled: closed, // disable field if entry is in detail page and for now, only E type permitted
            options: [
              statusEntrada === "Afectada/Cerrada"
                ? {
                    value: true,
                    text: "Afectada/Cerrada",
                    color: "danger",
                  }
                : statusEntrada === "Cerrada"
                ? {
                    value: true,
                    text: "Cerrada",
                    color: "success",
                  }
                : statusEntrada === "Afectada/En proceso"
                ? {
                    value: false,
                    text: "Afectada/En proceso",
                    color: "warning",
                  }
                : statusEntrada === "En proceso"
                ? {
                    value: false,
                    text: "En proceso",
                    color: "info",
                  }
                : {
                    value: true,
                    text: "Abierto",
                    color: "primary",
                  },
            ],
          },

          isCreateAnEntrance
            ? {
                label: "No. de factura",
                name: "cf_no_factura",
                type: "text",
                required: true,
                disabled: false, // disable field if entry is in detail page and if is not precharged
              }
            : closed
            ? {
                label: "No. de factura",
                name: "cf_no_factura",
                type: "text",
                required: true,
                disabled: false,
              }
            : {
                label: "No. de factura",
                name: "cf_no_factura",
                type: "text",
                required: true,
                disabled: true, // disable field if entry is in detail page and if is not precharged
              },
          isCreateAnEntrance
            ? {
                label: "Fecha sello factura",
                name: "cf_fecha_factura",
                type: "date",
                required: true,
                disabled: false, // disable field if entry is in detail page and if is not precharged
              }
            : closed
            ? {
                label: "Fecha sello factura",
                name: "cf_fecha_factura",
                type: "date",
                required: true,
                disabled: false,
              }
            : {
                label: "Fecha sello factura",
                name: "cf_fecha_factura",
                type: "date",
                required: true,
                disabled: true, // disable field if entry is in detail page and if is not precharged
              },
          isCreateAnEntrance
            ? {
                label: "No. de remisión",
                name: "cf_no_remision",
                type: "text",
                required: true,
                disabled: false, // disable field if entry is in detail page and if is not precharged
              }
            : {
                label: "No. de remisión",
                name: "cf_no_remision",
                type: "text",
                required: true,
                disabled: true, // disable field if entry is in detail page and if is not precharged
              },
          isCreateAnEntrance
            ? {
                label: "Fecha de la remisión",
                name: "cf_fecha_remision",
                type: "date",
                required: true,
                disabled: false,
              }
            : {
                label: "Fecha de la remisión",
                name: "cf_fecha_remision",
                type: "date",
                required: true,
                disabled: true,
              },
          isCreateAnEntrance
            ? {
                label: "Importe",
                name: "cf_importe_neto",
                type: "text",
                isPrice: true,
                disabled: false, // disable field if entry is in detail page
              }
            : {
                label: "Importe",
                name: "cf_importe_neto",
                type: "text",
                isPrice: true,
                disabled: true, // disable field if entry is in detail page
              },
          {
            label: "Factura",
            name: "factura",
            type: "file-auto",
            required: false,
            uppercase: true,
            accept: "application/pdf",
            uploadFunction: addEntryFilePDFAlmacen,
            disabled: false,
          },
          {
            label: "Remisión",
            name: "remision",
            type: "file-auto",
            required: false,
            uppercase: true,
            accept: "application/pdf",
            uploadFunction: addEntryFilePDFAlmacen,
            disabled: false,
          },
        ]
      : [
          {
            label: "Tipo de entrada",
            name: "tipo_entrada",
            type: "dropdown-chip",
            required: true,
            disabled:
              disabled ||
              isStock ||
              isRecurrent ||
              isOrdinary ||
              isRevolvente ||
              isGastos,
            options: isStock
              ? [
                  {
                    value: "STOCK",
                    text: "Stock",
                    color: "primary",
                  },
                ]
              : isRecurrent
              ? [
                  {
                    value: "RECURRENTE",
                    text: "Recurrente",
                    color: "danger",
                  },
                ]
              : isOrdinary
              ? [
                  {
                    value: "ORDINARIA",
                    text: "Ordinaria",
                    color: "info",
                  },
                ]
              : isRevolvente
              ? [
                  {
                    value: "FONDOREVOLVENTE",
                    text: "Fondo revolvente",
                    color: "primary",
                  },
                ]
              : isGastos
              ? [
                  {
                    value: "GASTOSCOMPROBAR",
                    text: "Gastos a comprobar",
                    color: "danger",
                  },
                ]
              : [
                  {
                    value: "",
                    text: "Seleccione tipo de entrada",
                    color: "dark",
                  },
                  {
                    value: "STOCK",
                    text: "Stock",
                    color: "primary",
                  },
                  {
                    value: "RECURRENTE",
                    text: "Recurrente",
                    color: "danger",
                  },
                  {
                    value: "ORDINARIA",
                    text: "Ordinaria",
                    color: "info",
                  },
                  {
                    value: "LIMPIEZA",
                    text: "Mantenimiento y Limpieza",
                    color: "warning",
                  },
                  {
                    value: "FONDOREVOLVENTE",
                    text: "Fondo revolvente",
                    color: "primary",
                  },
                  {
                    value: "GASTOSCOMPROBAR",
                    text: "Gastos a comprobar",
                    color: "danger",
                  },
                ],
          },
          (type === "ORDINARIA" ||
            type === "FONDOREVOLVENTE" ||
            type === "GASTOSCOMPROBAR" ||
            type === "STOCK" ||
            type === "RECURRENTE" ||
            type === "LIMPIEZA") && {
            label: "Fecha sello almacén",
            name: "cf_importe_neto",
            type: "date",
          },
          (type === "ORDINARIA" ||
            type === "FONDOREVOLVENTE" ||
            type === "GASTOSCOMPROBAR" ||
            type === "STOCK" ||
            type === "RECURRENTE" ||
            type === "LIMPIEZA") && {
            label: "Importe remisión",
            name: "cf_importe_remision",
            type: "text",
            isPrice: true,
          },
          type !== "ORDINARIA" && type !== "FONDOREVOLVENTE" && type !== "GASTOSCOMPROBAR"
            ? {
                label: "Proveedor",
                name: "supplier",
                type: "search",
                required: true,
                searchFunction: getProvidersAlmacen,
                disabled, // disable field if entry is in detail page
              }
            : {
                label: "Proveedor",
                name: "cf_ord_proveedor",
                type: "text",
                required: true,
                disabled, // disable field if entry is in detail page
              },
          (type === "ORDINARIA" ||
            type === "FONDOREVOLVENTE" ||
            type === "GASTOSCOMPROBAR" ||
            type === "LIMPIEZA") && {
            label: "Contrato",
            name: "cf_ord_no_contrato",
            type: "text",
            required: true,
            disabled, // disable field if entry is in detail page
          },
          type !== "ORDINARIA" &&
            type !== "FONDOREVOLVENTE" &&
            type !== "GASTOSCOMPROBAR" &&
            type !== "LIMPIEZA" && {
              label: "Contrato",
              name: "contract_number",
              type: "dropdown",
              required: true,
              disabled: Boolean(contracts.length === 0) || disabled,
              options: contracts,
            },
          type !== "ORDINARIA" &&
            type !== "FONDOREVOLVENTE" &&
            type !== "GASTOSCOMPROBAR" &&
            type !== "LIMPIEZA" && {
              label: "No. De requerimiento",
              name: "requirement",
              type: "dropdown",
              options: requirements,
              required: true,
              disabled: Boolean(requirements.length === 0) || disabled,
            },
          disabled && {
            label: "No. de entrada",
            name: "id",
            type: "text",
            required: true,
            focus: true,

            disabled,
          },
          (type === "ORDINARIA" ||
            type === "FONDOREVOLVENTE" ||
            type === "GASTOSCOMPROBAR") && {
            label: "Fecha de registro",
            name: "cf_ord_fecha_registro",
            type: "date",
            disabled, // disable field if entry is in detail page
          },
          (type === "ORDINARIA" ||
            type === "FONDOREVOLVENTE" ||
            type === "GASTOSCOMPROBAR") && {
            label: "Área solicitante",
            name: "cf_ord_area_solicitante",
            type: "text",
            disabled, // disable field if entry is in detail page
          },
          {
            label: "Fecha de entrada",
            name: "cf_fecha_entrada",
            type: "date",
            required: true,
            disabled: disabled && !isStock && !isPrecharge, // disable field if entry is in detail page and if is not precharged
          },

          {
            label: "Estado",
            name: "entrada_cerrada",
            type: "dropdown-chip",
            required: true,
            disabled: true, // disable field if entry is in detail page and for now, only E type permitted
            options: [
              statusEntrada === "Afectada/Cerrada"
                ? {
                    value: true,
                    text: "Afectada/Cerrada",
                    color: "danger",
                  }
                : statusEntrada === "Cerrada"
                ? {
                    value: true,
                    text: "Cerrada",
                    color: "success",
                  }
                : statusEntrada === "Afectada/En proceso"
                ? {
                    value: false,
                    text: "Afectada/En proceso",
                    color: "warning",
                  }
                : statusEntrada === "En proceso"
                ? {
                    value: false,
                    text: "En proceso",
                    color: "info",
                  }
                : {
                    value: true,
                    text: "Abierto",
                    color: "primary",
                  },
            ],
          },
          closed
            ? {
                label: "Fecha sello factura",
                name: "cf_fecha_factura",
                type: "date",
                required: true,
                disabled: false, // disable field if entry is in detail page and if is not precharged
              }
            : {
                label: "Fecha sello factura",
                name: "cf_fecha_factura",
                type: "date",
                required: true,
                disabled: disabled && !isStock && !isPrecharge, // disable field if entry is in detail page and if is not precharged
              },
          closed
            ? {
                label: "No. de factura",
                name: "cf_no_factura",
                type: "text",
                required: true,
                disabled: false,
              }
            : {
                label: "No. de factura",
                name: "cf_no_factura",
                type: "text",
                required: true,
                disabled: disabled && !isStock && !isPrecharge, // disable field if entry is in detail page and if is not precharged
              },
          disabled && {
            label: "Fecha de creación",
            name: "created_at",
            type: "date",
            required: false,
            // disabled is true if entrance is already created,
            disabled: true,
          },
          {
            label: "No. de remisión",
            name: "cf_no_remision",
            type: "text",
            required: true,
            disabled: disabled && !isStock && !isPrecharge, // disable field if entry is in detail page and if is not precharged
          },
          {
            label: "Fecha de la remisión",
            name: "cf_fecha_remision",
            type: "date",
            required: true,
            disabled: disabled && !isStock && !isPrecharge, // disable field if entry is in detail page and if is not precharged
          },
          (type === "FONDOREVOLVENTE" || type === "GASTOSCOMPROBAR") && {
            label: "Importe",
            name: "cf_importe_neto",
            type: "text",
            isPrice: true,
            disabled: closed, // disable field if entry is in detail page
          },
          type !== "RECURRENTE" ||
            (isCreateAnEntrance && {
              label: "Importe",
              name: "cf_importe_neto",
              type: "text",
              isPrice: true,
              disabled, // disable field if entry is in detail page
            }),
          (isGastosEntranceAndPrecarga ||
            isGastosEntranceAndCerrar ||
            isFondoEntranceAndPrecarga ||
            isFondoEntranceAndCerrar) && {
            label: "Factura",
            name: "factura",
            type: "file-auto",
            required: false,
            uppercase: true,
            accept: "application/pdf",
            uploadFunction: addEntryFilePDFAlmacen,
            disabled: false,
          },
          (isGastosEntranceAndPrecarga ||
            isGastosEntranceAndCerrar ||
            isFondoEntranceAndPrecarga ||
            isFondoEntranceAndCerrar) && {
            label: "Remisión",
            name: "remision",
            type: "file-auto",
            required: false,
            uppercase: true,
            accept: "application/pdf",
            uploadFunction: addEntryFilePDFAlmacen,
            disabled: false,
          },
        ]
  );
};
export const amountInputs = (decimals = 2) => [
  {
    label: "Subtotal",
    placeholder: "Subtotal",
    name: "subtotal",
    type: "text",
    isPrice: true,
    disabled: true,
    decimals: 4,
  },
  {
    label: "IVA",
    placeholder: "IVA",
    name: "iva",
    type: "text",
    isPrice: true,
    disabled: true,
    decimals: 4,
  },
  {
    label: "Importe neto",
    placeholder: "Importe neto",
    name: "total",
    type: "text",
    isPrice: true,
    disabled: true,
    decimals: 4,
  },
];

export const formGeneralRecurrent = (closed) => [
  {
    label: "Cantidad",
    name: "cf_cantidad",
    type: "text",
    disabled: closed,
  },
  {
    type: "line-break",
  },
  {
    label: "Descripción",
    name: "descripcion",
    type: "text",
    disabled: true,
    multiline: true,
    rows: 4,
    width: "100%",
  },
];

export const formRecurrent = (noInv, closed) => [
  noInv && {
    label: "No de inventario",
    name: "no_inventario",
    type: "text",
    required: true,
    disabled: closed,
  },
  {
    label: "Marca",
    name: "marca",
    type: "text",
    disabled: true,
  },
  {
    label: "Modelo",
    name: "modelo",
    type: "text",
    disabled: true,
  },
  noInv && {
    label: "Serie",
    name: "serie",
    type: "text",
    required: true,
    disabled: closed,
  },
];

export const formOrdinary = (disabled) => [
  {
    label: "Nombre del artículo",
    name: "nombre",
    type: "text",
    required: true,
    width: 415,
    disabled,
  },
  // {
  //   label: 'Marca',
  //   name: 'marca',
  //   type: 'text',
  //   required: true,
  //   disabled,
  // },
  // {
  //   label: 'Modelo',
  //   name: 'modelo',
  //   type: 'text',
  //   required: true,
  //   disabled,
  // },
  // {
  //   label: 'Serie',
  //   name: 'serie',
  //   type: 'text',
  //   required: true,
  //   disabled,
  // },
  {
    label: "Descripción adicional",
    name: "descripcion",
    type: "text",
    required: true,
    width: 415,
    disabled,
  },
  // {
  //   label: 'Tipo de presentación',
  //   name: 'presentation',
  //   type: 'text',
  //   required: true,
  //   disabled,
  // },
  {
    label: "Precio",
    name: "precio_unitario",
    type: "text",
    required: true,
    isPrice: true,
    disabled,
  },
  // {
  //   label: 'Foto del bien',
  //   name: 'foto',
  //   type: 'photo',
  //   required: true,
  // },
];

export const accordionBatchColumns = (id) => [
  // {
  // 	text: 'Presentación del proveedor',
  // 	value: 'presentation',
  // 	type: 'text',
  //   disabled: true
  // },
  // {
  // 	text: 'Equivalencia en stock (piezas)',
  // 	value: 'stock',
  // 	type: 'text',
  //   disabled: true
  // },
  {
    text: "Número de bienes entregados",
    value: "cf_cantidad",
    type: "text",
  },
  // {
  //   text: 'Equivalencia',
  //   value: 'equivalence',
  //   type: 'text',
  //   disabled: true,
  // },
  // id && {
  //   text: 'Actualizar cantidad',
  //   value: 'edit',
  //   type: 'icon',
  // },
];

export const getContractsSupplier = (supplier) => {
  if (supplier)
    return getContractList(100, 1, `supplier_number=${supplier}`)
      .then((res) => {
        if (res.status === 200 && res.data?.results[0]?.id) return res.data.results;
        else notice("No se encontraron contratos con este proveedor");
      })
      .catch((e) => {
        notice("No se encontraron contratos con este proveedor");
        return e;
      });
};

export const getRequirementsContract = (contract, setterFunction) => {
  if (contract)
    listRequirements(1, 100, `contract=${contract}`)
      .then(({ status, data }) => {
        if (status === 200 && data?.results[0]?.id)
          setterFunction(
            data.results.map((req) => ({
              value: req.id,
              text: req.name_off,
              text2: req.total_completed_percent === 100 && "Completado",
              disabled: req.total_completed_percent === 100,
            }))
          );
        else notice("No se encontraron requerimientos con este contrato");
      })
      .catch((e) => {
        console.log(e);
        notice("No se encontraron requerimientos con este contrato");
        return;
      });
};

export const clearRequest = (form) => {
  let fechaFactura;
  if (form.cf_fecha_factura) {
    fechaFactura = form.cf_fecha_factura?.includes("/")
      ? requestFormatDate(form.cf_fecha_factura)
      : form.cf_fecha_factura;
  } else {
    fechaFactura = null;
  }
  console.log(form);
  let payload = {
    ...form,
    cf_importe_remision_factura: form.cf_importe_remision_factura
      ? Number(form.cf_importe_remision_factura.replaceAll(",", ""))
      : null,
    // form.tipo_entrada === "RECURRENTE"
    //   ? Number(new_importe_remision.replaceAll(",", ""))
    //   : Number(new_importe_remision.replaceAll(",", "")), // remove when RECURRENTES LOADS IMPORT
    cf_fecha_remision: form.cf_fecha_remision ? form.cf_fecha_remision : null,
    cf_fecha_factura: fechaFactura,
    cf_ord_fecha_registro: form.cf_ord_fecha_registro ? form.cf_ord_fecha_registro : null,
    cf_fecha_entrada: form.cf_fecha_entrada ? form.cf_fecha_entrada : null,
    cf_no_proveedor: form?.supplier?.text2,
    cf_ord_no_contrato: form.cf_ord_no_contrato,
    warehouse_entry_details: form.requisition_item_details
      .map((item) =>
        form.tipo_entrada === "STOCK" &&
        item.item_total_delivered_amount !== item.requirement_item_total_amount
          ? {
              id: item.id,
              requisition_item_detail:
                item.requisition_item_detail != null
                  ? item.requisition_item_detail
                  : item.id,
              cf_cve_articulo: item.articulo_data?.cve_articulo,
              cf_cantidad: Number(item.cf_cantidad),
            }
          : form.tipo_entrada === "LIMPIEZA"
          ? {
              cf_cve_articulo: item.cf_cve_articulo,
              cf_cantidad: Number(item.cf_cantidad),
              cf_precio_unit: item.cf_precio_unit,
            }
          : {
              id: item.id,
              requisition_item_detail:
                item.requisition_item_detail != null
                  ? item.requisition_item_detail
                  : item.id,
              cf_observaciones: item.cf_observaciones,
              cf_cve_articulo: item.articulo_data?.cve_articulo,
              cf_cantidad: Number(item.cf_cantidad),
              recurrent_assets: item.recurrent_assets.map((rec) => ({
                ...rec,
                inventariable: item.inventariable,
                descripcion: item.descripcion,
              })),
            }
      )
      .filter((item) => item.cf_cantidad.toString()),
  };

  delete payload.requisition_item_details;
  delete payload.supplier;
  delete payload.items;
  delete payload.total_requirement_items_amount;
  delete payload.total_entry_delivered_items_amount;
  if (!payload.cf_fecha_factura) delete payload.cf_fecha_factura;

  return payload;
};

export const closeEntry = (form) => {
  let fechaFactura;
  if (form.cf_fecha_factura) {
    fechaFactura = form.cf_fecha_factura?.includes("/")
      ? requestFormatDate(form.cf_fecha_factura)
      : form.cf_fecha_factura;
  } else {
    fechaFactura = null;
  }
  let payload = {
    ...form,
    cf_fecha_remision: form.cf_fecha_remision ? form.cf_fecha_remision : null,
    cf_fecha_factura: fechaFactura,
    cf_ord_fecha_registro: form.cf_ord_fecha_registro ? form.cf_ord_fecha_registro : null,
    cf_fecha_entrada: form.cf_fecha_entrada ? form.cf_fecha_entrada : null,
    cf_no_proveedor: form.cf_no_proveedor || form?.supplier?.text2,
    warehouse_entry_details:
      form.tipo_entrada === "STOCK"
        ? form.requisition_item_details
            .map((item) => ({
              id: item.id,
              cf_cve_articulo: item.cf_cve_articulo,
              cf_cantidad: Number(item.cf_cantidad),
              requisition_item_detail: item.requisition_item_detail,
            }))
            .filter((item) => item.cf_cantidad)
        : form.tipo_entrada === "LIMPIEZA"
        ? form.requisition_item_details
            .map((item) => ({
              id: typeof item.id === "string" ? null : item.id,
              cf_precio_unit: item.cf_precio_unit,
              cf_cve_articulo: item.cf_cve_articulo,
              cf_cantidad: Number(item.cf_cantidad),
            }))
            .filter((item) => item.cf_cantidad)
        : form.requisition_item_details
            .map((item) => ({
              id: item.id,
              requisition_item_detail: item.requisition_item_detail,
              cf_observaciones: item.cf_observaciones,
              cf_cve_articulo: item.articulo_data?.cve_articulo,
              cf_cantidad: Number(item.cf_cantidad),
              recurrent_assets: item.recurrent_assets.map((rec) => ({
                ...rec,
                inventariable: item.inventariable,
                descripcion: item.descripcion,
              })),
            }))
            .filter((item) => item.cf_cantidad),
  };

  delete payload.requisition_item_details;
  delete payload.supplier;
  delete payload.warehouse_request;

  return payload;
};

export const ordinaryRequest = (form) => {
  let new_importe_remision;
  let fechaFactura;
  if (form.cf_fecha_factura) {
    fechaFactura = form.cf_fecha_factura?.includes("/")
      ? requestFormatDate(form.cf_fecha_factura)
      : form.cf_fecha_factura;
  } else {
    fechaFactura = null;
  }
  // if (
  //   form.cf_importe_remision_factura == null ||
  //   form.cf_importe_remision_factura === ""
  // ) {
  //   new_importe_remision = "0";
  // } else {
  //   new_importe_remision = form.cf_importe_remision_factura;
  // }
  let payload = {
    ...form,
    cf_fecha_entrada: form.cf_fecha_entrada ? form.cf_fecha_entrada : null,
    cf_fecha_factura: fechaFactura,
    cf_ord_fecha_registro: form.cf_ord_fecha_registro ? form.cf_ord_fecha_registro : null,
    cf_importe_neto: form.cf_importe_neto
      ? Number(form.cf_importe_neto.replaceAll(",", ""))
      : 0,
    cf_importe_remision_factura: form.cf_importe_remision_factura
      ? Number(form.cf_importe_remision_factura.replaceAll(",", ""))
      : null,

    cf_importe_remision: form.cf_importe_remision
      ? Number(form?.cf_importe_remision?.replaceAll(",", ""))
      : null,
    cf_fecha_remision: form.cf_fecha_remision ? form.cf_fecha_remision : null,
    // warehouse_entry_details: form.ordinary_item_details.map(item => ({
    //   asset_ordinary: {
    //     nombre: item.nombre,
    //     descripcion: item.descripcion,
    //     marca: item.marca,
    //     modelo: item.modelo,
    //     serie: item.serie,
    //     nro_inventario: item.nro_inventario,
    //     precio_unitario: item.precio_unitario,
    //     inventariable: item.inventariable,
    //   }
    // }))
  };
  if (
    !payload.cf_importe_remision_factura ||
    payload.cf_importe_remision_factura === null ||
    payload.cf_importe_remision_factura === ""
  ) {
    delete payload.cf_importe_remision_factura;
  }
  delete payload.warehouse_entry_details;
  delete payload.requisition_item_details;
  delete payload.supplier;
  return payload;
};

export const requirementData = (entrance, arr) => {
  if (entrance.id) {
    return entrance.warehouse_entry_details.map((item, i) => ({
      ...item,
      ivaDisplay: item.iva ? "Si" : "No",
      descripcion: item.recurrent_assets[0]?.descripcion,
      requirement: entrance.requirement,
      item_total_delivered_amount: item.entry_item_total_delivered_amount,
      requested_amount: item.requirement_item_total_amount,
      status: !Boolean(item.entry_item_total_delivered_amount < item.requested_amount),
      warehouse_entry_detail: item.warehouse_entry,
      cf_cantidad: Number.isInteger(Number(item.cf_cantidad))
        ? Number(item.cf_cantidad)?.toFixed()
        : item.cf_cantidad,
      cf_precio_unit: Number(item.cf_precio_unit)?.toFixed(4),
      subtotal:
        Number(item.cf_cantidad)?.toFixed(4) * Number(item.cf_precio_unit)?.toFixed(4),
    }));
  } else {
    return arr.map((item, i) => ({
      ...item,
      warehouse_entry_detail:
        entrance.warehouse_entry_details[0]?.id &&
        entrance.warehouse_entry_details[i]?.requisition_item_detail === item.id
          ? entrance.warehouse_entry_details[i]?.id
          : 0,
      requirement_item_total_amount: item.requested_amount || 0,
      cf_cve_articulo: item.articulo_data?.cve_articulo,
      cf_articulo: item.articulo_data?.desc_corta,
      cf_cantidad: 0,
      status: !Boolean(item.item_total_delivered_amount < item.requested_amount),
      inventariable: item.articulo_data?.inventariable,
      cf_precio_unit: Number(item.unit_price)?.toFixed(4),
      iva: item.articulo_data?.iva,
      ivaDisplay: item.articulo_data?.iva ? "Si" : "No",
      subtotal: 0,
      cf_observaciones: "",
      descripcion: item.articulo_data?.desc_amplia,
      recurrent_assets: [
        {
          marca: item.articulo_data?.articulo_marca,
          modelo: item.articulo_data?.articulo_modelo,
          serie: "",
        },
      ],
    }));
  }
};

// REFUND DATA

export const defaultRefund = {
  // "cf_a_pedido": "",
  cf_fecha_entrada: "",
  cf_fecha_factura: "",
  cf_fecha_remision: "",
  cf_fol_bita: "",
  // "cf_hora_ent": "",
  // "cf_no_almacen": "",
  cf_no_factura: "",
  cf_no_pedido: "",
  cf_no_remision: "",
  cf_observaciones: "",
  cf_tipo_documento: "",
  contract_number: "",
  cf_ord_area_solicitante: "Dirección de Control Patrimonial",
  // warehouse_request: "",
  warehouse_entry_details: [],
};

export const refundFormInputs = (disabled) => [
  {
    label: "Proveedor",
    name: "supplier",
    type: "search",
    searchFunction: getProvidersAlmacen,
    width: 200,
    disabled,
  },
  {
    label: "Contrato",
    name: "cf_ord_no_contrato",
    type: "text",
    required: true,
    disabled,
  },
  /*   {
    label: "No. De requerimiento",
    name: "requirement",
    type: "dropdown",
    options: requirements,
    required: true,
    disabled: Boolean(requirements.length === 0) || closed,
  }, */
  {
    label: "Área solicitante",
    // placeholder:
    placeholder: "Dirección de Control Patrimonial",
    name: "cf_ord_area_solicitante",
    type: "text",
    disabled,
    width: 278,
  },
  {
    label: "Recibido",
    placeholder: "Almacen",
    name: "cf_recibido",
    type: "text",
    disabled,
  },
  {
    label: "Fecha sello almacén",
    name: "cf_fecha_entrada",
    type: "date",
    required: true,
    disabled,
  },
  {
    label: "Fecha sello factura",
    name: "cf_fecha_factura",
    type: "date",
    required: true,
    disabled,
    // disable field if entry is in detail page and if is not precharged
  },
  {
    label: "No. de factura",
    name: "cf_no_factura",
    type: "text",
    required: true,
    disabled,
  },
  {
    label: "Importe factura",
    name: "cf_importe_remision_factura",
    type: "text",
    isPrice: true,
    disabled,
    // disable field if entry is in detail page
  },
  {
    label: "Fecha sello remisión",
    name: "cf_fecha_remision",
    type: "date",
    disabled,
    required: true,
  },
  {
    label: "No. de remisión",
    name: "cf_no_remision",
    type: "text",
    required: true,
    disabled,
  },
  {
    label: "Importe remisión",
    name: "cf_importe_remision",
    type: "text",
    isPrice: true,
    disabled,
  },
  /*  {
    label: "Factura",
    name: "factura",
    type: "file-auto",
    required: false,
    uppercase: true,
    accept: "application/pdf",
    uploadFunction: addEntryFilePDFAlmacen,
    disabled,
  },
  {
    label: "Remisión",
    name: "remision",
    type: "file-auto",
    required: false,
    uppercase: true,
    accept: "application/pdf",
    uploadFunction: addEntryFilePDFAlmacen,
    disabled,
  }, */
];

export const defaultRefundArticles = {
  cve_article: "",
  cf_articulo: "",
  cf_cantidad: 0,
};

export const refund_columns_articles = (disabled) => [
  {
    text: "Clave Art.",
    value: "cf_cve_articulo",
    type: "search",
    searchFunction: getArticles,
    disabled,
  },
  {
    text: "Nombre del artículo",
    value: "cf_articulo",
    type: "search",
    searchFunction: getArticles,
    uppercase: false,
    disabled,
  },
  {
    text: "Cantidad",
    value: "cf_cantidad",
    type: "text",
    disabled,
  },
  {
    text: "Acciones",
    type: "icon",
    value: "delete",
    disabled,
  },
];

export const clearRefundRequest = (form) => ({
  ...form,
  cf_fecha_factura: form.cf_fecha_entrada,
  warehouse_entry_details: form.warehouse_entry_details.map(
    ({ cf_cve_articulo, cf_cantidad }) => ({
      cf_cve_articulo,
      cf_cantidad,
    })
  ),
});

// CLEAN ENTRY
export const cleanBatchColumns = (entrada_cerrada) => [
  {
    text: "Clave Art",
    value: "cf_cve_articulo",
    type: "text",
    width: 96,
    disabled: true,
  },
  {
    text: "Nombre del artículo",
    value: "cf_articulo",
    type: "text",
    uppercase: false,
    width: 900,
    disabled: true,
    multiline: true,
    rows: 4,
  },
  {
    text: "Cantidad",
    value: "cf_cantidad",
    type: "text",
    width: 100,
    disabled: entrada_cerrada,
  },
  {
    text: "Precio unitario",
    value: "cf_precio_unit",
    type: "text",
    isPrice: true,
    decimals: 4,
    disabled: entrada_cerrada,
    width: 200,
  },
  {
    text: "IVA",
    value: "ivaDisplay",
    type: "text",
    disabled: true,
    width: 200,
  },
  {
    text: "Subtotal",
    value: "subtotal",
    type: "text",
    isPrice: true,
    decimals: 4,
    disabled: true,
    width: 150,
  },
  {
    text: "Unidad de distribución",
    value: "uni_distribuye",
    type: "text",
    disabled: true,
    width: 185,
  },
  {
    text: "Acciones",
    type: "icon",
    value: "delete",
    disabled: entrada_cerrada,
  },
];

export const defaultCleanArticle = {
  cve_article: "",
  cf_articulo: "",
  cf_cantidad: 0,
  cf_precio_unit: 0,
  iva: "",
};
